export const LS_USER = 'user';

export const saveItem = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item));
  return item;
};

export const saveItemInArray = (key, item) => {
  // Comprobamos si la key ya existe
  let items = JSON.parse( localStorage.getItem( key ) );
  if( Array.isArray( items ) ) items.push( item )
  else items = [ item ];
  // Guardamos en el localStorage
  localStorage.setItem(key, JSON.stringify(item));
  return items;
};