import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content } from "./components/Content/Content";
import { Nav } from "./components/Nav/Nav";
import { refreshUser } from "./helpers/functions";
import logo192 from './assets/logo_cfp_192.png';
import './App.css';

function App() {
  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Obtenemos el usuario y el site desde Redux
  const user = useSelector( state => state.user );
  const site = useSelector( state => state.site );
  refreshUser( user, site, dispatch );

  console.log('site', site);

  useEffect( () => {
    document.title = "CFP Dardo Rocha"
  },[]);

  return (
    <div className="layout">
    
      {/* Cabecera */}
      <header className="header">
        <div className="gridHeader">
          <div className="divLogo centerImage">
            <img src={logo192}></img>
          </div>
          <div className="divTitle">
            <h1>CFP Dardo Rocha</h1>
          </div>
        </div>
      </header>

      {/* Barra de navegacion */}
      <Nav/>

      {/* Contenido principal */}
      <Content/>

      {/* <aside className="lateral">
        <div>
          <p>Soy el lateral</p>
        </div>
      </aside> */}

      <footer className="footer">
      <strong>Moduchip</strong> &copy; <a href="https://moduchip.com.ar" target="_blank" >moduchip.com.ar</a>
      </footer>

    </div>
  );
}

export default App;
