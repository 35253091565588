import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { LS_USER } from '../../../helpers/storage';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import './NewSchoolYear.css';
import { LessonSchedulesDto, NewSchoolYearDto } from './NewSchoolYearDto';
import { months } from '../../../constants/months';
import { days } from '../../../constants/days';
import { nf, updateSite } from '../../../helpers/functions';
import { ROUTE_SCHOOL_YEAR_CREATE } from '../../../api-rest/back-cfp';
import { SITE_SCHOOL_YEARS } from "../../../constants/sites";
import { simpleRunFetch } from '../../../helpers/fetch';
import { useDispatch } from "react-redux";
import { ComeBack } from '../ComeBack/ComeBack';
import { saveSite } from "../../../features/site/siteSlice";
import { updateSiteHistory } from '../../../features/siteHistory/siteHistorySlice';

export const NewSchoolYear = () => {

  // Constantes
  const FROM = 'FROM';
  const TO = 'TO';

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Historico de navegacion
  const siteHistory = useSelector( state => state.siteHistory );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Obtenemos el subject desde Redux
  const subject = useSelector( state => state.subject );

  // Seteo de fecha inicio y fecha de fin
  const [ fromDate, setFromDate ] = useState( new Date() );
  const [ toDate, setToDate ] = useState( new Date() );

  // Array para el seteo de los dias y horarios
  const [ daySetting, setDaySetting ] = useState([]);

  // Array para el seteo de los meses no lucrativos
  const [ notLucrativeMonths, setNotLucrativeMonths ] = useState([]);

  // Mensaje
  const footerMessage = useRef(null);
  // footerMessage.current.innerText = 'escribir un mensaje'; // se puede hacer un innerHTML, etc

  // Estado de los buttons
  const [ disabled, setDisabled ] = useState( false );

  // Init daySetting
  useEffect( () => {
    // Set Days
    const daySets = days.map( day => {
      return {
        ...day,
        check: false,
        fromHour: new Date(),
        toHour: new Date()
      };
    });
    setDaySetting( daySets );
    // Set Months
    const monthSets = months.map( month => {
      return {
          ...month,
        check: false
      };
    });
    setNotLucrativeMonths( monthSets );
  }, [] );

  const comeBack = () => {
    // Casteamos a array el historico de sitios
    const arraySiteHistory = Array.from( siteHistory.site );
    // Le quitamos al array el ultimo de sus elementos y lo guardamos en la variable siteBack
    const siteBack = arraySiteHistory.pop();
    // Guardamos el nuevo array de siteHistory
    dispatch( updateSiteHistory( arraySiteHistory ) );
    // Actualizamos el nuevo site
    dispatch( saveSite( siteBack ) );
  }

  const changeDayCheck = ( e, index ) => {
    let copyDaySetting = [ ...daySetting ];
    copyDaySetting[ index ].check = e.target.checked;
    setDaySetting( copyDaySetting );
  }

  const changeDaySettingHour = ( e, index, what ) => {
    let copyDaySetting = [ ...daySetting ];
    if( what === FROM )
      copyDaySetting[index].fromHour = e;
    else
      copyDaySetting[index].toHour = e;
    setDaySetting( copyDaySetting );
  }

  const changeNotLucrativeMonths = (e, index) => {
    let copyNotLucrativeMonths = [ ...notLucrativeMonths ];
    copyNotLucrativeMonths[ index ].check = e.target.checked;
    setNotLucrativeMonths( copyNotLucrativeMonths );
  }

  const createNewSchoolYear = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    setDisabled( true );

    const newSchoolYearDto = new NewSchoolYearDto();
    newSchoolYearDto.subject_id = subject.id;
    newSchoolYearDto.frequency_id = e.target.frequency.value;
    newSchoolYearDto.start_date = `${fromDate.getFullYear()}-${nf(fromDate.getMonth()+1,2)}-${nf(fromDate.getDate(),2)}`;
    newSchoolYearDto.end_date = `${toDate.getFullYear()}-${nf(toDate.getMonth()+1,2)}-${nf(toDate.getDate(),2)}`;
    if(e.target.quota.value) newSchoolYearDto.quota = e.target.quota.value; else delete newSchoolYearDto['quota'];
    if(e.target.classroom.value) newSchoolYearDto.classroom = e.target.classroom.value; else delete newSchoolYearDto['classroom'];

    newSchoolYearDto.lesson_schedules = daySetting.map( (day ) => {
      if( day.check ){
        return new LessonSchedulesDto(
          day.numberId,
          day.fromHour.getHours(),
          day.fromHour.getMinutes(),
          day.toHour.getHours(),
          day.toHour.getMinutes(),
        );
      }
    }).filter( e => e );

    newSchoolYearDto.not_lucrative_months = notLucrativeMonths.map( month => {
      if( month.check ) return month.numberId;
    }).filter( e => e );

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_SCHOOL_YEAR_CREATE, newSchoolYearDto, authorization );
    }catch(e){
      console.log('-> ', e);
      footerMessage.current.innerText = 'Error, intente nuevamente';
      return;
    }

    // Limpiamos el formulario
    e.target.reset();

    setDisabled( false );

    comeBack()
  }

  const cancelNewSchoolYear = () => {
    // updateSite( dispatch, site, SITE_SCHOOL_YEARS );
    comeBack();
  }

  // Metodos del modal 'NewSubject'
  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  return (
    <div>
      { 
        !disabled &&
        <ComeBack />
      }
      <div className='title'>
        <h1>Nuevo ciclo lectivo - { subject.name }</h1>
      </div>
      <div>
        <form onSubmit={createNewSchoolYear}>
          <div className='layoutNewSchoolYear'>

            <div className='col1' >
              <label>Desde</label><br />
              <DatePicker value={fromDate} onChange={setFromDate} /><br /><br />

              <label>Hasta</label><br />
              <DatePicker value={toDate} onChange={setToDate} /><br /><br />

              <label>Frecuencia</label><br />
              <select name="frequency" id="frequency">
                <option value="1">Semanal</option>
                <option value="2">Quincenal</option>
              </select>
              <br /><br />

              <label>Cupo (Opcional)</label><br />
              <input type={'number'} name='quota' placeholder='Cupo' ></input>
              <br /><br />

              <label>Aula (Opcional)</label><br />
              <input type={'text'} name='classroom' placeholder='Aula' ></input>
            </div>

            <div className='col2' >
              <table className='tableForList' >
                <tr className='oddRow'>
                  <th></th>
                  <th>Día</th>
                  <th>Hora de inicio</th>
                  <th>Hora de finalización</th>
                </tr>

                {
                  daySetting.map( (day, index) => {
                    return (
                      <tr className={getClassForRow(index)}>
                        <td><input type="checkbox" id={day.id} onChange={e => changeDayCheck(e, index)} /></td>
                        <td>{day.name}</td>
                        <td><TimePicker value={daySetting[index].fromHour} onChange={ e => changeDaySettingHour( e, index, FROM ) } /></td>
                        <td><TimePicker value={daySetting[index].toHour} onChange={ e => changeDaySettingHour( e, index, TO ) } /></td>
                      </tr>
                    );
                  })
                }
              </table>
            </div>

            <div className='col3'>
              <table className='tableForList'>
                <tr className='oddRow'>
                  <th></th>
                  <th>Meses no lucrativos</th>
                </tr>
                {
                  notLucrativeMonths.map( (month, index) => {
                    return (
                      <tr className={getClassForRow(index)}>
                        <td><input type="checkbox" id={month.id} onChange={ e => changeNotLucrativeMonths(e, index)} /></td>
                        <td>{month.name}</td>
                      </tr>
                    );
                  })
                }
              </table>
            </div>

            <div className='footerButtons'>
              <input className='confirmationButton' type={'submit'} name='send' value={'Confirmar'} disabled={disabled} />&nbsp;
              <button className='cancelButton' onClick={cancelNewSchoolYear} type={'button'} disabled={disabled} >Cancelar</button>
            </div>

            <div className='footerMessage' ref={footerMessage}></div>

          </div>
          
        </form>
      </div>
    </div>
  )
}
