import { createSlice } from '@reduxjs/toolkit'
import { SITE_LOGIN } from '../../constants/sites';

const initialState = {
  started_state: false,
  site: SITE_LOGIN,
}

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    saveSite: ( state, action ) => {
      state.started_state = true;
      state.site          = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveSite } = siteSlice.actions

export default siteSlice.reducer