import React, { useEffect, useState } from 'react'
import { LS_USER } from '../../../../helpers/storage';
import { ROUTE_SCHOOL_YEAR_FEES_PAID_ON_TOTAL_FEES } from '../../../../api-rest/back-cfp';
import { simpleRunFetch } from '../../../../helpers/fetch';


export const FeesPaidOnTotalFees = ({ schoolYearId }) => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;

  const [ feesPaidOnTotalFees, setFeesPaidOnTotalFees ] = useState( null);

  useEffect(() => {
    console.log('schoolYearId:', schoolYearId);
    setFeesPaidOnTotalFees(null);
  }, [schoolYearId]);

  const getFeesPaidOnTotalFees = async () => {

    const route = [...ROUTE_SCHOOL_YEAR_FEES_PAID_ON_TOTAL_FEES];
    route[0] = route[0].replace( '{school_year_id}', schoolYearId );
     
    let response;
    try{ 
      response = await simpleRunFetch( route, null, authorization );
      console.log( 'response FeesPaidOnTotalFees:', JSON.stringify( response, null, 4 ) );
      setFeesPaidOnTotalFees( response );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  return (
    <>
      {
        !feesPaidOnTotalFees &&
        <button onClick={getFeesPaidOnTotalFees}>Calcular</button>
      }
      {
        feesPaidOnTotalFees &&
        <>{feesPaidOnTotalFees.fees_paid}/{feesPaidOnTotalFees.total_fees}</>
      }
    </>
  )
}
