import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { LS_USER } from '../../../helpers/storage';
import { ComeBack } from '../ComeBack/ComeBack';
import { ROUTE_ATTENDANCE_AT_LESSON_SHOW, ROUTE_ATTENDANCE_AT_LESSON_TAKE_ATTENDACE, ROUTE_MONTHLY_FEES_CHECK_DEBT } from '../../../api-rest/back-cfp';
import { simpleRunFetch } from '../../../helpers/fetch';
import { days } from '../../../constants/days';
import { nf, updateSite, formatDateFull } from '../../../helpers/functions';
import Modal from 'react-modal';
import { months } from '../../../constants/months';
import { saveInscription } from '../../../features/inscription/inscriptionSlice';
import { SITE_MONTHLY_FEES } from '../../../constants/sites';
import { QrReader } from 'react-qr-reader';
import './AttendanceAtLessons.css';

const modalStyles = {
  content: {
    width: '35%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

let dateReadQR;

export const AttendanceAtLessons = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Obtenemos los datos que hay en Redux
  const lesson = useSelector( state => state.lesson );

  const [ lessonDetail, setLessonDetail ] = useState({});
  const [ attendanceIndex, setAttendanceIndex ] = useState(0);
  const [ monthlyFeesDue, setMonthlyFeesDue ] = useState([]);
  const [modalMonthlyFeesDueIsOpen, setModalMonthlyFeesDueIsOpen] = useState(false);
  const [modalAttendanceByQRIsOpen, setModalAttendanceByQRIsOpen] = useState(false);
  const [ QRValue, setQRValue ] =  useState(null);
  const [ invalidQR, setInvalidQR ] = useState(false);
  const [ attendanceByQR, setAttendanceByQR ] = useState(false);

  const TOLERANCE_MILLIS_READ_QR = 5000;

  useEffect( () => {
    getLessonDetail();
    dateReadQR = new Date();
  }, [] );

  useEffect( () => {
    takeAttendanceByQR();
  }, [QRValue]);

  const getLessonDetail = async () => {
    let auxLessonDetail;
    try{
      let auxRoute = [...ROUTE_ATTENDANCE_AT_LESSON_SHOW];
      auxRoute[0] = auxRoute[0] + lesson.id;
      auxLessonDetail = await simpleRunFetch( auxRoute, null, authorization );
      // Agregamos la propiedad 'className'
      console.log( 'auxLessonDetail', JSON.stringify( auxLessonDetail, null, 4 ) );
      setLessonDetail( auxLessonDetail.lesson );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const takeAttendance = async (e, attendance, index) => {
    setAttendanceIndex( index );
    attendance.attended = e.target.checked;
    // Actualizamos la DB
    if( await updateTakeAttendace( attendance ) ){
      // Si la actualizacion de la DB fue exitosa, actualizamos el hook. Debemos enviar una replica/copia de lessonDetail
      setLessonDetail( {...lessonDetail} );
      // Si el alumno esta asistiendo a clase, buscamos los meses que adeuda
      if( !attendance.attended ) return;
      const monthlyFeesDue = await monthlyFeesCheckDebt( attendance );
      // Si adeuda
      if( monthlyFeesDue.length > 0 ){
        setMonthlyFeesDue( monthlyFeesDue );
        openModalMonthlyFeesDue();
      }
      else closeModalMonthlyFeesDue();
      return;
    }
  }

  const updateTakeAttendace = async (attendance) => {
    try{
      const responseTakeAttendace = await simpleRunFetch( ROUTE_ATTENDANCE_AT_LESSON_TAKE_ATTENDACE, attendance, authorization );
      console.log( 'responseTakeAttendace', JSON.stringify( responseTakeAttendace, null, 4 ) );
      return true;
    }catch(e){
      console.log('-> ', e);
      return false;
    }
  }

  const monthlyFeesCheckDebt = async (attendance) => {
    const date = new Date();
    attendance['today_date'] = `${date.getFullYear()}-${nf(date.getMonth()+1,2)}-${nf(date.getDate(),2)}`;

    try{
      const responseMonthlyFeesCheckDebt = await simpleRunFetch( ROUTE_MONTHLY_FEES_CHECK_DEBT, attendance, authorization );
      console.log( 'responseMonthlyFeesCheckDebt', JSON.stringify( responseMonthlyFeesCheckDebt, null, 4 ) );
      return responseMonthlyFeesCheckDebt?.monthlyFees;
    }catch(e){
      console.log('-> ', e);
      return false;
    }
  }

  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  const openModalMonthlyFeesDue = () => {
    console.log('que hay ameo')
    setModalMonthlyFeesDueIsOpen(true);
  }

  const closeModalMonthlyFeesDue = () => {
    setModalMonthlyFeesDueIsOpen(false);
  }

  const goMonthlyFees = (e, attendance) => {
    // console.log('estoooo', JSON.stringify( attendance, null, 4 ));
    dispatch( saveInscription( { id: attendance.inscription_id } ));
    updateSite( dispatch, site, SITE_MONTHLY_FEES );
  }

  const goAttendanceByQR = () => {
    setModalAttendanceByQRIsOpen( true );
  }

  const closeModalAttendanceByQR = () => {
    setModalAttendanceByQRIsOpen(false);
  }

  const webcamReadQR = ( result, error ) => {
    if (!!result) {
      // const currentDate = new Date();
      // const deltaMillisReadQR = Math.abs( currentDate - dateReadQR );
      // console.log( 'deltaMillisReadQR', deltaMillisReadQR );
      
      // dateReadQR = currentDate;
      // if( deltaMillisReadQR < TOLERANCE_MILLIS_READ_QR ) return;
      // console.log('paso');

      try{
        const resultJson = JSON.parse(result?.text);
        if(
          resultJson.user_id &&
          resultJson.role_id &&
          resultJson.name &&
          resultJson.last_name
        ){
          setQRValue( resultJson );
        } else{
          console.log('El QR no es valido.');
          setQRValue( null );
        }
      }catch(e){
        console.info(e);
        console.log('Fallo el parseo del QR');
        setQRValue( null );
      }
    }
    if (!!error) {
      // console.info(error);
      setAttendanceByQR(false);
      setInvalidQR(false);
    }
  }

  const takeAttendanceByQR = async () => {
    if( !QRValue ){
      setAttendanceByQR(false);
      setInvalidQR(true);
      return;
    }

    // Si el QR es valido, buscamos el 'attendance_at_lesson' del alumno
    const attendance = lessonDetail?.attendance_at_lessons?.filter( (attendance, index) => {
      if( attendance.user_id === QRValue.user_id ){
        setAttendanceIndex( index );
        return true;
      } return false;
    })
    .map( attendance => {
      // Actualizamos el valor en 'lessonDetail'
      attendance.attended = true;
      // Construimos el objeto
      return {
        user_id: QRValue.user_id,
        lesson_id: attendance.lesson_id,
        inscription_id: attendance.inscription_id,
        attended: attendance.attended
      }
    })[0];

    console.log( 'QUIERO VER EL CAMBIO lessonDetail', lessonDetail )

    // Si el alumno no pertenece al ciclo, motramos el mensaje de QR invalido
    if( !attendance ){
      setAttendanceByQR(false);
      setInvalidQR(true);
      return;
    }

    console.log('Esto essss para tomar asistencia!:', attendance);
    // Actualizamos la asistencia en la base de datos
    if( await updateTakeAttendace( attendance ) ){
      setAttendanceByQR(true);
      // Si la actualizacion de la DB fue exitosa, actualizamos el hook. Debemos enviar una replica/copia de lessonDetail
      setLessonDetail( {...lessonDetail} );
      // Buscamos si el alumno adeuda meses
      const monthlyFeesDue = await monthlyFeesCheckDebt( attendance );
      // Si adeuda
      if( monthlyFeesDue.length > 0 ){
        setMonthlyFeesDue( monthlyFeesDue );
        openModalMonthlyFeesDue();
      }
      else closeModalMonthlyFeesDue();
      return;
    }

  }

  return (
    <div>
      <ComeBack />
      <div className='title'>
        {/* <h1>{`${lessonDetail.school_year?.subject?.name} - Desde ${lessonDetail.school_year?.start_date} hasta ${lessonDetail.school_year?.end_date}`}</h1> */}
        <h1>{lessonDetail.school_year?.subject?.name}</h1>
      </div>
      <div>
        <h3>
          { 
            days.filter( element => element.numberId === lessonDetail.lesson_schedule?.day_number)
            .map( element => {
              return element.name
            })
          } de { nf(lessonDetail.lesson_schedule?.start_time,2) }:{ nf(lessonDetail.lesson_schedule?.start_minute,2) } a { nf(lessonDetail.lesson_schedule?.end_time,2) }:{ nf(lessonDetail.lesson_schedule?.end_minute,2) } hs.
        </h3>
      </div>
      <div>
        <h3>Tomar asistencia - { formatDateFull( lessonDetail.date_dictated ) }</h3>
      </div>
      <div>
        <button onClick={goAttendanceByQR}>Asistencia por QR</button>
      </div>
      <div>
        <table className='tableForList' >
          <tr className='oddRow'>
            <th className='column'>Nombre</th>
            <th className='column'>Apellido</th>
            <th className='column'>DNI</th>
            <th className='column'>Email</th>
            <th className='column'>Asistió</th>
            <th className='column'>Cuotas</th>
          </tr>

          {
            lessonDetail.attendance_at_lessons?.map( (attendance, index) => {
              return (
                <tr className={getClassForRow(index)}>
                  <td className='column'>{attendance.user.name}</td>
                  <td className='column'>{attendance.user.last_name}</td>
                  <td className='column'>{attendance.user.identification_number}</td>
                  <td className='column'>{attendance.user.email}</td>
                  <td className='column'><input type='checkbox' checked={attendance.attended} onClick={ e => takeAttendance(e, attendance, index) } /></td>
                  <td className='column'><button onClick={ e => goMonthlyFees( e, attendance ) }>Cuotas</button></td>
                </tr>
              );
            })
          }

        </table>
      </div>

      {/* Modal QR */}
      <Modal
        isOpen={modalAttendanceByQRIsOpen}
        onRequestClose={closeModalAttendanceByQR}
        style={modalStyles}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Modal para tomar asistencia por QR"
      >
        <h2>Tomar asistencia por QR</h2>
        <div className='layoutAttendanceByQR'>
          <div className='col1'>
            <QrReader
              scanDelay='1000'
              onResult={webcamReadQR}
              style={{ width: '100%' }}
            />
            <button onClick={closeModalAttendanceByQR} type={'button'} >Cerrar</button>
          </div>
          <div className='col2'>

            {
              attendanceByQR && QRValue &&
              (
                <>
                  <h3>¡Excelente!</h3>
                  <p>
                    Se tomó asistencia de<br/>
                    {QRValue.name} {QRValue.last_name}
                  </p>
                </>
              )
            }

            {
              invalidQR &&
              (
                <>
                  <h3>Error</h3>
                  <p>
                    Falló la lectura del QR, el QR no es valido, o el alumno no pertenece a este ciclo.
                  </p>
                </>
              )
            }

          </div>
        </div>

      </Modal>

      <Modal
        isOpen={modalMonthlyFeesDueIsOpen}
        onRequestClose={closeModalMonthlyFeesDue}
        style={modalStyles}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Modal que informa sobre cuotas adeudadas"
      >
        <div>

          {
            lessonDetail?.attendance_at_lessons?.filter( (attendance, index) => index === attendanceIndex )
            .map( attendance => {
              return (
                <>
                  <h2>Atención</h2>
                  <p>El alumno <strong>{attendance.user.name} {attendance.user.last_name}</strong> adeuda las siguientes cuotas:</p>
                  <ul>
                  {
                    monthlyFeesDue.map( feeDue => {
                      return (
                        <>
                          <li>{ months[ parseInt( feeDue.monthly_fee_date.split('-')[1] ) - 1 ].name } del { feeDue.monthly_fee_date.split('-')[0] }</li>
                        </>
                      );
                    })
                  }
                  </ul>

                  <p>
                    Si en este momento está abonando alguna de las cuotas presione <strong>'Si'</strong> para dirigirse a la planilla de cuotas del alumno.
                    De lo contrario presione <strong>'No'</strong>.
                  </p>
                  <button onClick={ e => goMonthlyFees(e, attendance) }>Si</button>&nbsp;
                  <button onClick={closeModalMonthlyFeesDue}>No</button>
                  
                </>
              );
            })
          }

        </div>
      </Modal>

    </div>
  )
}
