export const days = [
  {
    id: 'sunday',
    name: 'Domingo',
    numberId: 0
  },
  {
    id: 'monday',
    name: 'Lunes',
    numberId: 1
  },
  {
    id: 'tuesday',
    name: 'Martes',
    numberId: 2
  },
  {
    id: 'wednesday',
    name: 'Miércoles',
    numberId: 3
  },
  {
    id: 'thursday',
    name: 'Jueves',
    numberId: 4
  },
  {
    id: 'friday',
    name: 'Viernes',
    numberId: 5
  },
  {
    id: 'saturday',
    name: 'Sábado',
    numberId: 6
  }
];