import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { saveInscription } from '../../../features/inscription/inscriptionSlice';
import { LS_USER } from '../../../helpers/storage';
import { ROUTE_INSCRIPTION_SHOW_AND_CHECK_DEBT } from '../../../api-rest/back-cfp';
import { SITE_MONTHLY_FEES } from '../../../constants/sites';
import { simpleRunFetch } from '../../../helpers/fetch';
import { nf, updateSite } from '../../../helpers/functions';
import { QrReader } from 'react-qr-reader';
import Modal from 'react-modal';
import './ChargeFees.css';

const modalStyles = {
  content: {
    width: '35%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const ChargeFees = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  const [ QRValue, setQRValue ] =  useState(null);
  const [ QRValueCopy, setQRValueCopy ] =  useState(null);
  const [modalChargeFeesIsOpen, setModalChargeFeesIsOpen] = useState(false);

  const [inscriptions, setInscriptions] = useState([]);

  useEffect(() => {
    // console.log(QRValue);
    if( !QRValueCopy && QRValue ){
      setQRValueCopy(QRValue);
      openModalChargeFees();
    }
  }, [QRValue]);

  useEffect(() => {
    console.log(QRValueCopy);
    
  }, [QRValueCopy]);

  useEffect(() => {
    console.log(QRValueCopy, QRValueCopy);
    if(QRValueCopy) getInscriptions();
  }, [QRValueCopy]);

  const getInscriptions = async () => {

    const date = new Date();
    const today_date = `${date.getFullYear()}-${nf(date.getMonth()+1,2)}-${nf(date.getDate(),2)}`;

    const route = [...ROUTE_INSCRIPTION_SHOW_AND_CHECK_DEBT];
    route[0] = route[0].replace( '{user_id}', QRValueCopy.user_id ).replace( '{today_date}', today_date );
     
    let response;
    try{ 
      response = await simpleRunFetch( route, null, authorization );
      console.log( 'response inscriptions:', JSON.stringify( response, null, 4 ) );
    }catch(e){
      console.log('-> ', e);
      return;
    }

    // Filtramos solo las inscripciones que no estan finalizadas o que tienen deuda
    response = response.filter( inscription => 
      new Date(inscription.school_year?.end_date) > new Date()
      || inscription.monthlyFees > 0
    );
    setInscriptions(response);
    
  }

  const webcamReadQR = ( result, error ) => {
    if(QRValue) return;
    if (!!result) {
      try{
        const resultJson = JSON.parse(result?.text);
        if(
          resultJson.user_id &&
          resultJson.role_id &&
          resultJson.name &&
          resultJson.last_name
        ){
          console.log('El QR es valido.');
          setQRValue( resultJson );
        } else{
          console.log('El QR no es valido.');
          setQRValue( null );
        }
      }catch(e){
        console.info(e);
        console.log('Fallo el parseo del QR');
        setQRValue( null );
      }
    }
    if (!!error) {
      // console.info(error);
      setQRValue( null );
    }
  }

  const openModalChargeFees = () => {
    setModalChargeFeesIsOpen(true);
  }

  const closeModalChargeFees = () => {
    setQRValueCopy(null);
    setModalChargeFeesIsOpen(false);
  }

  const getClassForRow = (index, invalid) => {
    if(invalid) return index % 2 === 0? 'invalidEvenRow' : 'invalidOddRow';
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  const goMonthlyFees = (e, inscription_id) => {
    dispatch( saveInscription( { id: inscription_id } ));
    updateSite( dispatch, site, SITE_MONTHLY_FEES );
  }

  return (
    <>
      <div className='title'>
        <h1>Cobrar cuotas</h1>
      </div>
      <div className='layoutChargeFees'>
        <div className='col1'>
          <h3>Buscar alumno por QR</h3>
          <div style={{width: '70%'}}>
            <QrReader
              scanDelay='1000'
              onResult={webcamReadQR}
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div className='col2'>
        </div>
      </div>

      <Modal
        isOpen={modalChargeFeesIsOpen}
        // onAfterOpen={afterOpenModalChargeFees}
        onRequestClose={closeModalChargeFees}
        style={modalStyles}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Modal que informa las inscripciones del alumno y cuales adeuda."
      >
        <h3>Alumno: {QRValueCopy?.name} {QRValueCopy?.last_name}</h3>
        <table className='tableForList'>
          <thead>
            <tr className='oddRow'>
              <th className='column'>Carrera</th>
              <th className='column'>Inicio del ciclo</th>
              <th className='column'>Final del ciclo</th>
              <th className='column'>Cuotas</th>
            </tr>
          </thead>
          <tbody>
          {
            inscriptions.map( (inscription, index) => (
              <tr key={index} className={getClassForRow(index, inscription.monthlyFees > 0)}>
                <td className='column'>{inscription?.school_year?.subject?.name}</td>
                <td className='column'>{inscription?.school_year?.start_date}</td>
                <td className='column'>{inscription?.school_year?.end_date}</td>
                <td className='column'><button onClick={(e) => goMonthlyFees(e, inscription.id)}>Cuotas</button></td>
              </tr>
            ))
          }
          </tbody>
        </table>
        <br/>
        <button onClick={closeModalChargeFees}>Cerrar</button>
      </Modal>
      
    </>
  );
}