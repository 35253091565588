import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_LOGIN } from '../../../api-rest/back-cfp';
import { saveUser } from "../../../features/user/userSlice";
import { simpleRunFetch } from '../../../helpers/fetch';
import { updateSite } from '../../../helpers/functions';
import { LS_USER, saveItem } from '../../../helpers/storage';
import { NAVIGATIONS } from '../../Nav/Nav';
import { PasswordInput } from '../../PasswordInput/PasswordInput'

export const Login = () => {
  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  const site = useSelector( state => state.site );

  const [ error, setError ] = useState(false);

  const login = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    // Obtenemos los datos del formulario
    const form = e.target;
    const email = form.email.value;
    const password = form.password.value;

    // Creamos el body para la peticion
    const body = {
      email,
      password
    }

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_LOGIN, body );
    }catch(e){
      console.log('-> ', e);
      setError(true);
      return;
    }

    // Si la consulta fue correcta, guardamos el resultado en el localStorage y en Redux
    saveItem( LS_USER, response );
    dispatch( saveUser( response ));

    // Redirigimos
    const sitePrimary = NAVIGATIONS[ response.role_id ].filter( e => {
      if( e.hasOwnProperty('primary') && e.primary === true ) return e; return false;
    });
    const siteRedirect = sitePrimary.length > 0? sitePrimary[0] : NAVIGATIONS[ response.role_id ][ 0 ];
    updateSite(dispatch, site, siteRedirect.site );
  }

  return (
    <div>
      <h1>Iniciar sesión</h1>
      <form onSubmit={login}>

        <label>Email</label><br />
        <input type={'text'} name='email' placeholder='Email' ></input><br/><br/>
        
        <PasswordInput label={'Contraseña'} name={'password'} placeholder='Contraseña'/><br/><br/>

        <input type={'submit'} name='send' value={'Ingresar'} />
      </form>
      {
        error &&
        <div className='error'>
          <h4>No se pudo iniciar sesión</h4>
          <p >
            Revisá que el email y la contraseña sean correctos
          </p>
        </div>
      }
    </div>
  )
}
