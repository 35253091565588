import React, { useEffect, useRef, useState } from 'react';
import { ROUTE_USER_INFO, ROUTE_USER_UPDATE_MY_PERSONAL_DATA, ROUTE_USER_CHANGE_PASSWORD } from '../../../api-rest/back-cfp';
import { simpleRunFetch } from '../../../helpers/fetch';
import { LS_USER } from '../../../helpers/storage';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DatePicker } from '@material-ui/pickers';
import { nf } from '../../../helpers/functions';
import Modal from 'react-modal';
import { PasswordInput } from '../../PasswordInput/PasswordInput';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const MyProfile = () => {
  
  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  const [ userInfo, setUserInfo ] = useState(null);
  const [ dateOfBirth, setDateOfBirth ] = useState( new Date() );
  const [ textMessage, setTextMessage ] = useState('');
  const [ colorTextMessage, setColorTextMessage ] = useState('');
  const refForm = useRef(null);

  const [ modalChangePasswordIsOpen, setModalChangePasswordIsOpen ] = useState(false);
  const [ errorChangePassword, setErrorChangePassword ] = useState( false );

  // Estado de los buttons
  const [ disabled, setDisabled ] = useState( false );

  useEffect( () => {
    getUserInfo();
  }, []);

  useEffect( () => {
    try{
      refForm.current.name.value                  = userInfo.name;
      refForm.current.last_name.value             = userInfo.last_name;
      refForm.current.identification_number.value = userInfo.identification_number;
      refForm.current.gender_id.value             = userInfo.gender_id;
      refForm.current.place_of_birth.value        = userInfo.place_of_birth;
      refForm.current.level_of_study_id.value     = userInfo.level_of_study_id;
      refForm.current.location.value              = userInfo.location;
      refForm.current.domicile.value              = userInfo.domicile;
      refForm.current.cellphone_number.value      = userInfo.cellphone_number;
      refForm.current.email.value                 = userInfo.email;
      setDateOfBirth( new Date( userInfo.date_of_birth + 'T00:00:00' ) );
    }catch(e){}
  }, [userInfo])

  // Metodo para pedir la info del usuario
  const getUserInfo = async () => {
    let response;
    try{ 
      response = await simpleRunFetch( ROUTE_USER_INFO, null, authorization );
      console.log('response', response);
      setUserInfo( response );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  };

  const updateMyPersonalData = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    const body = {
      level_of_study_id     : e.target.level_of_study_id.value,
      gender_id             : e.target.gender_id.value,
      // email                 : e.target.email.value,
      name                  : e.target.name.value,
      last_name             : e.target.last_name.value,
      // identification_number : e.target.identification_number.value,
      date_of_birth         : `${dateOfBirth.getFullYear()}-${nf(dateOfBirth.getMonth()+1,2)}-${nf(dateOfBirth.getDate(),2)}`,
      place_of_birth        : e.target.place_of_birth.value,
      location              : e.target.location.value,
      domicile              : e.target.domicile.value,
      cellphone_number      : e.target.cellphone_number.value,
      // phone_number          : e.target.phone_number.value
    }

    if( e.target.email.value !== userInfo.email )
      body['email'] = e.target.email.value;
    
    if( e.target.identification_number.value !== userInfo.identification_number.toString() )
      body['identification_number'] = e.target.identification_number.value;

    console.log( 'body', body );

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_USER_UPDATE_MY_PERSONAL_DATA, body, authorization );
    }catch(e){
      console.log('-> ', e);
      setTextMessage( 'Error, intente nuevamente' );
      setColorTextMessage( 'red' );
      return;
    }

    // setUserInfo( e.target );
    setUserInfo( {...userInfo, ...body} );
  }
  
  const openModalChangePassword = () => {
    setModalChangePasswordIsOpen(true);
    setErrorChangePassword(false);
  }

  const closeModalChangePassword = () => {
    setModalChangePasswordIsOpen(false);
  }

  const userChangePassword = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    setDisabled( true );

    const body = {
      current_password: e.target.current_password.value,
      new_password: e.target.new_password.value,
      repet_new_password: e.target.repet_new_password.value
    };

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_USER_CHANGE_PASSWORD, body, authorization );
      closeModalChangePassword();
    }catch(e){
      console.log('-> ', e);
      setErrorChangePassword( true );
    }

    setDisabled( false );

  }

  return (
    <>
      <div className='title'>
        <h1>Mi perfil</h1>
        <button onClick={openModalChangePassword}>Cambiar contraseña</button>

        <Modal
          isOpen={modalChangePasswordIsOpen}
          // onAfterOpen={afterOpenModalInscriptionIsOpen}
          onRequestClose={closeModalChangePassword}
          style={modalStyles}
          ariaHideApp={false}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          contentLabel="Modal para cambiar la contraseña"
        >
          <h2>Cambiar la contraseña</h2>
          <form onSubmit={userChangePassword} >
            
            <PasswordInput label={'Contraseña actual'} name={'current_password'} />
            <br /><br />

            <PasswordInput label={'Nueva contraseña'} name={'new_password'} />
            <br /><br />

            <PasswordInput label={'Repita la nueva contraseña'} name={'repet_new_password'} />
            <br /><br />

            <button onClick={closeModalChangePassword} disabled={disabled} type='button' >Cancelar</button>&nbsp;
            <input type={'submit'} name='send' value={'Confirmar'} disabled={disabled} />

          </form>
          {
            errorChangePassword &&
            <>
              <h4 style={{color:'red'}}>No se pudo cambiar la contraseña</h4>
              <p>  
                Revisa que la contraseña actual sea correcta;<br />
                que la nueva contraseña tenga al menos 8 carácteres;<br />
                y que el campo para repetir la contraseña este correcto.
              </p>
            </>
          }
        </Modal>

      </div>
      <form ref={refForm} onSubmit={updateMyPersonalData}>
        <div className='layoutRegister'>
          <div className='col1' >

            <h3>Datos personales</h3>
            
            <label>Nombres</label><br />
            <input type={'text'} name='name' placeholder='Nombres'  ></input><br/><br />

            <label>Apellidos</label><br />
            <input type={'text'} name='last_name' placeholder='Apellidos'  ></input><br/><br />
            
            <label>Documento de identidad</label><br />
            <input type={'number'} name='identification_number' placeholder='Documento de identidad'  ></input><br/><br />
            
            <label>Sexo</label><br />
            <select name="gender_id" id="gender_id">
              <option value="1">Masculino</option>
              <option value="2">Femenino</option>
            </select>
            <br /><br />

            <label>Fecha de nacimiento</label><br />
            <DatePicker value={dateOfBirth} onChange={setDateOfBirth} /><br /><br />

            <label>Lugar de nacimiento</label><br />
            <input type={'text'} name='place_of_birth' placeholder='Lugar de nacimiento'  ></input><br/><br />
            
            <label>Nivel completo de estudios</label><br />
            <select name="level_of_study_id" id="level_of_study_id">
              <option value="1">Primario</option>
              <option value="2">Secundario</option>
              <option value="3">Terciario</option>
              <option value="4">Universitario</option>
            </select>
            <br />

          </div>
          <div className='col2' >

            <h3>Datos de contacto</h3>
            
            <label>Localidad</label><br />
            <input type={'text'} name='location' placeholder='Localidad' ></input><br/><br />

            <label>Domicilio</label><br />
            <input type={'text'} name='domicile' placeholder='Domicilio' ></input><br/><br />
            
            <label>Celular</label><br />
            <input type={'text'} name='cellphone_number' placeholder='Celular' ></input><br/><br />
            
            <h3>Datos de la cuenta</h3>

            <label>Correo electrónico</label><br />
            <input type={'text'} name='email' placeholder='Correo electrónico' ></input><br/><br />

            <input type={'submit'} name='send' value={'Actualizar'} />

          </div>

          <div className='message'>
            <label style={{ color: colorTextMessage }}>{textMessage}</label>
          </div>
        </div>
      </form>
    </>
  )
}
