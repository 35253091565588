import React from 'react';
import { useSelector } from "react-redux";
import { Login } from './Login/Login'
import { SITE_LOGIN, SITE_REGISTER_STUDENT, SITE_SUBJECTS, SITE_SCHOOL_YEARS, SITE_LESSONS, SITE_RECEPTIONISTS, SITE_STUDENTS, SITE_CHARGE_FEES, SITE_INSCRIPTIONS, SITE_INSCRIPTION_SCHOOL_YEAR, SITE_SCHOOL_YEARS_STUDYING, SITE_NEW_SCHOOL_YEAR, SITE_GET_QR, SITE_ATTENDANCE_AT_LESSON, SITE_MONTHLY_FEES, SITE_MY_PROFILE, SITE_TAKE_ATTENDANCE_AUTOMATICALLY, SITE_ANOTHER_PERSON_PROFILE, SITE_REGISTER_RECEPTIONIST, SITE_TUITIONS } from '../../constants/sites';
import { GetQR } from './GetQR/GetQR';
import { Subjects } from './Subjects/Subjects';
import './Content.css';
import { SchoolYears } from './SchoolYears/SchoolYears';
import { NewSchoolYear } from './NewSchoolYear/NewSchoolYear';
import { Lessons } from './Lessons/Lessons';
import { Inscriptions } from './Inscriptions/Inscriptions';
import { InscriptionSchoolYear } from './InscriptionSchoolYear/InscriptionSchoolYear';
import { AttendanceAtLessons } from './AttendanceAtLessons/AttendanceAtLessons';
import { MonthlyFees } from './MonthlyFees/MonthlyFees';
import { MyProfile } from './MyProfile/MyProfile';
import { TakeAttendanceAutomatically } from './TakeAttendanceAutomatically/TakeAttendanceAutomatically';
import { SchoolYearsStudying } from './SchoolYearsStudying/SchoolYearsStudying';
import { Students } from './Students/Students';
import { AnotherPersonProfile } from './AnotherPersonProfile/AnotherPersonProfile';
import { Receptionists } from './Receptionists/Receptionists';
import { RegisterStudent } from './RegisterStudent/RegisterStudent';
import { RegisterReceptionist } from './RegisterReceptionist/RegisterReceptionist';
import { Tuitions } from './Tuitions/Tuitions';
import { ChargeFees } from './ChargeFees/ChargeFees';

export const Content = () => {
  // Obtenemos el site desde Redux que nos indica donde esta posicionado el usuario
  const site = useSelector( state => state.site );

  const renderSwitch = () => {
    switch( site.site ){
      
      case SITE_LOGIN:
        return <Login />

      case SITE_REGISTER_STUDENT:
        return <RegisterStudent />

      case SITE_REGISTER_RECEPTIONIST:
        return <RegisterReceptionist />

      case SITE_SUBJECTS:
        return <Subjects />

      case SITE_SCHOOL_YEARS:
        return <SchoolYears />

      case SITE_NEW_SCHOOL_YEAR:
        return <NewSchoolYear />

      case SITE_LESSONS:
        return <Lessons />

      case SITE_ATTENDANCE_AT_LESSON:
        return <AttendanceAtLessons />
      
      case SITE_MONTHLY_FEES:
        return <MonthlyFees />

      case SITE_RECEPTIONISTS:
        return <Receptionists />

      case SITE_STUDENTS:
        return <Students />

      case SITE_TUITIONS:
        return <Tuitions />

      case SITE_CHARGE_FEES:
        return <ChargeFees />;

      case SITE_INSCRIPTIONS:
        return <Inscriptions />;
      
      case SITE_INSCRIPTION_SCHOOL_YEAR:
        return <InscriptionSchoolYear />;

      case SITE_SCHOOL_YEARS_STUDYING:
        return <SchoolYearsStudying />

      case SITE_GET_QR:
        return <GetQR />;
      
      case SITE_MY_PROFILE:
        return <MyProfile />;

      case SITE_ANOTHER_PERSON_PROFILE:
        return <AnotherPersonProfile />;

      case SITE_TAKE_ATTENDANCE_AUTOMATICALLY:
        return <TakeAttendanceAutomatically />;

      default:
        return 'default';
    }
  }

  return (
    <section className="content">
      <div>
        { renderSwitch() }
      </div>
    </section>
  )
}
