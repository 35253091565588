import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { simpleRunFetch } from '../../../helpers/fetch';
import { ROUTE_SUBJECT_CREATE, ROUTE_SUBJECT_SHOW } from '../../../api-rest/back-cfp';
import { LS_USER } from '../../../helpers/storage';
import { saveSubject } from "../../../features/subject/subjectSlice";
import { saveSite } from "../../../features/site/siteSlice";
import { SITE_INSCRIPTION_SCHOOL_YEAR } from "../../../constants/sites";
import { updateSite } from "../../../helpers/functions";

export const Inscriptions = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );
  const [ subjects, setSubjects ] = useState( [] );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Metodo para pedir las carreras
  const getSubjects = async () => {
    let subjectsAux;
    try{ 
      subjectsAux = await simpleRunFetch( ROUTE_SUBJECT_SHOW, null, authorization );
      console.log( 'subjectsAux', JSON.stringify( subjectsAux, null, 4 ) );
      setSubjects( subjectsAux.subjects );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  };
   
  // UseEffect a modo 'Constructor' para pedir cargar las carreras
  useEffect( () => {
    getSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] );

  // Metodos del modal 'NewSubject'
  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  // Boton entrar a carrera
  const enterSubject = (event, element) => {
    dispatch( saveSubject( element ));
    updateSite( dispatch, site, SITE_INSCRIPTION_SCHOOL_YEAR );
  }

  return (
    <div>
      <div className='title'>
        <h1>Carreras</h1>
      </div>
      <div className='title'>
        <h3>Lista de carreras</h3>
      </div>
      <div>
        <table className='tableForList' >
          <tr className='oddRow'>
            <th className='column'>Nombre</th>
            <th className='column'>Ver ciclos</th>
          </tr>
          { 
            subjects.map( (subject, index) => {
              return (
                <tr className={getClassForRow(index)}>
                  <td className='column'>{subject.name}</td>
                  <td className='column'><button onClick={e => enterSubject(e, subject)}>Ver ciclos</button></td>
                </tr>
              );
            }) 
          }
        </table>
      </div>
    </div>
  )
}
