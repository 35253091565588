import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  started_state: false,
  id: undefined,
  subject_id: undefined,
  type_school_year_id: undefined,
  frequency_id: undefined,
  start_date: undefined,
  end_date: undefined,
  open_registration: undefined,
}

export const schoolYearSlice = createSlice({
  name: 'schoolYear',
  initialState,
  reducers: {
    saveSchoolYear: ( state, action ) => {
      state.started_state       = true;
      state.id                  = action.payload.id;
      state.subject_id          = action.payload.subject_id;
      state.type_school_year_id = action.payload.type_school_year_id;
      state.frequency_id        = action.payload.frequency_id;
      state.start_date          = action.payload.start_date;
      state.end_date            = action.payload.end_date;
      state.open_registration   = action.payload.open_registration;
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveSchoolYear } = schoolYearSlice.actions

export default schoolYearSlice.reducer