import React, { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { simpleRunFetch } from '../../helpers/fetch';
import { ROUTE_USER_REGISTER } from '../../api-rest/back-cfp';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import { nf } from '../../helpers/functions';
import './Register.css';

export const Register = ( {role_id, title, submitValue, successMessage} ) => {

  const [ dateOfBirth, setDateOfBirth ] = useState( new Date() );
  const [ textMessage, setTextMessage ] = useState('');
  const [ colorTextMessage, setColorTextMessage ] = useState('');

  const [ error, setError ] = useState(false);

  const register = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    if( e.target.password.value !== e.target.repeat_password.value ){
      setTextMessage('Las contraseñas no coinciden');
      setColorTextMessage( 'red' );
      setError(false);
      return;
    } else setTextMessage('');

    // Creamos el body para la peticion
    let body = undefined;
    try{

      body = {
        role_id:                role_id,
        level_of_study_id:      e.target.level_of_study_id.value,
        gender_id:              e.target.gender_id.value,
        email:                  e.target.email.value,
        password:               e.target.password.value,
        name:                   e.target.name.value,
        last_name:              e.target.last_name.value,
        identification_number:  e.target.identification_number.value,
        date_of_birth:          `${dateOfBirth.getFullYear()}-${nf(dateOfBirth.getMonth()+1,2)}-${nf(dateOfBirth.getDate(),2)}`,
        place_of_birth:         e.target.place_of_birth.value,
        location:               e.target.location.value,
        domicile:               e.target.domicile.value,
        cellphone_number:       e.target.cellphone_number.value,
        // phone_number:           e.target.phone_number.value,
      }
    }catch(e){
      console.log('-> ', e);
      setTextMessage( 'Error en los campos' );
      setColorTextMessage( 'red' );
      return;
    }
    
    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_USER_REGISTER, body );
    }catch(e){
      console.log('-> ', e);
      setError(true);
      return;
    }

    setTextMessage( successMessage  );
    setColorTextMessage( 'green' );
    setError(false);

    // Limpiamos el formulario
    e.target.reset();

  };

  return (
    <form onSubmit={register}>
      <div className='layoutRegister'>
        <div className='title'>
          <h1>{title}</h1>
        </div>
        <div className='col1' >

          <h3>Datos personales</h3>
          
          <label>Nombres</label><br />
          <input type={'text'} name='name' placeholder='Nombres'  ></input><br/><br />

          <label>Apellidos</label><br />
          <input type={'text'} name='last_name' placeholder='Apellidos'  ></input><br/><br />
          
          <label>Documento de identidad</label><br />
          <input type={'number'} name='identification_number' placeholder='Documento de identidad'  ></input><br/><br />
          
          <label>Sexo</label><br />
          <select name="gender_id" id="gender_id">
            <option value="1">Masculino</option>
            <option value="2">Femenino</option>
          </select>
          <br /><br />

          <label>Fecha de nacimiento</label><br />
          <DatePicker value={dateOfBirth} onChange={setDateOfBirth} /><br /><br />

          <label>Lugar de nacimiento</label><br />
          <input type={'text'} name='place_of_birth' placeholder='Lugar de nacimiento'  ></input><br/><br />
          
          <label>Nivel completo de estudios</label><br />
          <select name="level_of_study_id" id="level_of_study_id">
            <option value="1">Primario</option>
            <option value="2">Secundario</option>
            <option value="3">Terciario</option>
            <option value="4">Universitario</option>
          </select>
          <br />

        </div>
        <div className='col2' >

          <h3>Datos de contacto</h3>
          
          <label>Localidad</label><br />
          <input type={'text'} name='location' placeholder='Localidad' ></input><br/><br />

          <label>Domicilio</label><br />
          <input type={'text'} name='domicile' placeholder='Domicilio' ></input><br/><br />
          
          <label>Celular</label><br />
          <input type={'text'} name='cellphone_number' placeholder='Celular' ></input><br/><br />
          
          <h3>Datos de la cuenta</h3>

          <label>Correo electrónico</label><br />
          <input type={'text'} name='email' placeholder='Correo electrónico' ></input><br/><br />

          <PasswordInput 
            name={'password'}
            label={'Contraseña'}
            placeholder={'Contraseña'}
          />
          <br/><br />

          <PasswordInput 
            name={'repeat_password'}
            label={'Repita la contraseña'}
            placeholder={'Repita la contraseña'}
          />
          <br/><br />

          <input type={'submit'} name='send' value={ submitValue } />

        </div>

        <div className='col3' ></div>

        <div className='message'>
          <label style={{ color: colorTextMessage }}>{textMessage}</label>

          {
            error &&
            <div className='error' >
              <h4>Error al registrar</h4>
              <p>
                Revisá que la contraseña contenga al menos 8 carácteres.<br/>
                Si el error persiste, comunicate con el administrador.
              </p>
            </div>
          }

        </div>
      </div>
    </form>
  )
}
