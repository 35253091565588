export class NewSchoolYearDto {
  subject_id;
  frequency_id;
  start_date;
  end_date;
  quota;
  classroom;
  not_lucrative_months;
  lesson_schedules;
}

export class LessonSchedulesDto {
  constructor(
    day_number,
    start_time,
    start_minute,
    end_time,
    end_minute
  ){
    this.day_number = day_number;
    this.start_time = start_time;
    this.start_minute = start_minute;
    this.end_time = end_time;
    this.end_minute = end_minute;
  }
}

// {
//   "subject_id": 1,
//   "frequency_id": 1,
//   "start_date": "2019-08-01",
//   "end_date": "2020-06-30",
//   "quota": 26,
//   "classroom": "H17",
//   "not_lucrative_months": [1],
//   "lesson_schedules": [
//        {
//           "day_number": 2,
//           "start_time": 16,
//           "start_minute": 0,
//           "end_time": 20,
//           "end_minute": 30
//       },
//        {
//           "day_number": 4,
//           "start_time": 10,
//           "start_minute": 0,
//           "end_time": 12,
//           "end_minute": 0
//       },
//       {
//           "day_number": 5,
//           "start_time": 10,
//           "start_minute": 0,
//           "end_time": 12,
//           "end_minute": 0
//       },
//       {
//           "day_number": 6,
//           "start_time": 10,
//           "start_minute": 0,
//           "end_time": 12,
//           "end_minute": 0
//       }
//   ]
// }