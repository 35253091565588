import React from 'react';
import { ROLE_RECEPTIONIST } from '../../../constants/roles';
import { Register } from '../../Register/Register';
import { ComeBack } from '../ComeBack/ComeBack';

export const RegisterReceptionist = () => {
  return (
    <>
      <ComeBack />
      <Register
        role_id={ ROLE_RECEPTIONIST }
        title={ 'Registrar un nuevo recepcionista' }
        submitValue={ 'Registrar' }
        successMessage={ '¡Excelente! Has registrado correctamente al recepcionista' }
      />
    </>
  )
}
  