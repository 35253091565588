import React, { useEffect, useState } from 'react';
import { LS_USER } from '../../../helpers/storage';
import { ROUTE_TUITION_SHOW, ROUTE_TUITION_CREATE, ROUTE_TUITION_DELETE } from '../../../api-rest/back-cfp';
import { simpleRunFetch } from "../../../helpers/fetch";
import { formatTimestampDateFull } from "../../../helpers/functions";
import Modal from 'react-modal';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const Tuitions = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;

  const [ aTuition, setATuition ] = useState( null );
  const [ tuitions, setTuitions ] = useState([]);
  const [ showOnlyAvailable, setShowOnlyAvailable ] = useState( true );

  const [modalNewTuitionIsOpen, setModalNewTuitionIsOpen] = useState(false);
  const [ errorNewTuition, setErrorNewTuition ] = useState( false );

  const [modalDeleteTuitionIsOpen, setModalDeleteTuitionIsOpen] = useState(false);

  useEffect( () => {
    getTuitions();
  }, []);

  useEffect( () => {
    getTuitions();
  }, [showOnlyAvailable]);

  const getTuitions = async () => {
    const route = [...ROUTE_TUITION_SHOW];
    route[0] = showOnlyAvailable? route[0]+'?onlyAvailable=true' : route[0];
    try{
      const response = await simpleRunFetch( route, null, authorization );
      setTuitions( response.tuitions );
      console.log('-> ', response);
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const changeShowOnlyAvailable = (e) => {
    setShowOnlyAvailable( e.target.checked );
  }

  const openModalNewTuition = () => {
    setModalNewTuitionIsOpen(true);
    setErrorNewTuition(false);
  }

  const afterOpenModalNewTuition = () => {
    // potencialmente usable
  }

  const closeModalNewTuition = () => {
    setModalNewTuitionIsOpen(false);
  }

  const createNewTuition = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    // Verificamos que se haya completado el formulario
    if( !e.target.voucher.value ) return;

    // Creamos el body para la peticion
    const body = {
      voucher: e.target.voucher.value.trim()
    }

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_TUITION_CREATE, body, authorization );
    }catch(e){
      console.log('-> ', e);
      setErrorNewTuition(true);
      return;
    }

    getTuitions();
    setModalNewTuitionIsOpen(false);
  }

  const openModalDeleteTuition = (event, tuition) => {
    setATuition(tuition);
    setModalDeleteTuitionIsOpen(true);
  }

  const afterOpenModalDeleteTuition = () => {
    // potencialmente usable
  }

  const closeModalDeleteTuition = () => {
    setModalDeleteTuitionIsOpen(false);
  }

  const deleteTuition = async () => {
    const route = [...ROUTE_TUITION_DELETE];
    route[0] = route[0].replace('{tuition_id}', aTuition.id);
    try{
      await simpleRunFetch( route, null, authorization );
    }catch(e){
      console.log('-> ', e);
      return;
    }
    getTuitions();
    setModalDeleteTuitionIsOpen(false);
  }

  const getClassForRow = (index, inscription) => {
    if(inscription) return index % 2 === 0? 'validEvenRow' : 'validOddRow';
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }
  
  return (
    <>
      <div className='title'>
        <h1>Matrículas</h1>
      </div>
      <div>
        <button onClick={openModalNewTuition}>Nueva matrícula</button>
        <Modal
            isOpen={modalNewTuitionIsOpen}
            onAfterOpen={afterOpenModalNewTuition}
            onRequestClose={closeModalNewTuition}
            style={modalStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            contentLabel="Modal para crear una nueva matrícula"
          >
            <div>
              <h2>Crear nueva matrícula</h2>
              <p>Introduzca el valor alfanúmerico del comprobante</p>
              <form onSubmit={createNewTuition}>
                <input type={'text'} name='voucher' placeholder='Comprobante' ></input><br /><br />
                <input className='confirmationButton' type={'submit'} name='send' value={'Aceptar'} />&nbsp;
                <button className='cancelButton' onClick={closeModalNewTuition}>Cancelar</button>
              </form>
              {
                errorNewTuition &&
                <p className='error'>
                  Error al crear la matrícula.<br/>
                  Aseguresé que el valor del comprobante sea nuevo.
                </p>
              }
            </div>
          </Modal>
      </div>
      <div>
          <h3>Lista de matrículas</h3>
          <input type="checkbox" id="cbox" checked={showOnlyAvailable} onChange={changeShowOnlyAvailable}/> <label for="cbox">Disponibles</label>
      </div>
      <table className='tableForList' >
        <tr className='oddRow'>
          <th className='column'>Comprobante</th>
          <th className='column'>Fecha</th>
          <th className='column'>Carrera</th>
          <th className='column'>Apellido</th>
          <th className='column'>Nombre</th>
          <th className='column'>DNI</th>
          <th className='column'>Eliminar</th>
        </tr>
        { 
          tuitions &&
          tuitions.map( ( tuition, index ) => {
            const inscription = tuition.inscription[0]? tuition.inscription[0] : null;
            return (
              <tr className={getClassForRow(index, inscription)} key={index}>
                <td className='column'>{tuition.voucher}</td>
                <td className='column'>{formatTimestampDateFull(tuition.voucher_date)}</td>
                <td className='column'>{tuition.inscription[0]?.school_year.subject.name}</td>
                <td className='column'>{tuition.inscription[0]?.user.last_name}</td>
                <td className='column'>{tuition.inscription[0]?.user.name}</td>
                <td className='column'>{tuition.inscription[0]?.user.identification_number}</td>
                <td className='column'>
                  <button className='button' onClick={e => openModalDeleteTuition(e, tuition)} disabled={inscription} >Eliminar</button>
                </td>
              </tr>
            )
          })
        }
      </table>
      <Modal
        isOpen={modalDeleteTuitionIsOpen}
        // onAfterOpen={}
        onRequestClose={closeModalDeleteTuition}
        style={modalStyles}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Modal para eliminar una matrícula"
      >
        <div>
          <h2>Eliminar matrícula</h2>
          <p>¿Está seguro que desea eliminar la matrícula <strong>{aTuition?.voucher}</strong>?</p>
          <button className='confirmationButton' onClick={deleteTuition}>Aceptar</button>&nbsp;
          <button className='cancelButton' onClick={closeModalDeleteTuition}>Cancelar</button>
        </div>
      </Modal>
    </>
  )
}
