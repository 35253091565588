import { saveUser } from "../features/user/userSlice";
import { LS_USER } from './storage';
import { saveSite } from "../features/site/siteSlice";
import { saveSiteHistory } from '../features/siteHistory/siteHistorySlice';
import { ROLE_UNDEFINED, ROLE_ROOT, ROLE_ADMIN, ROLE_RECEPTIONIST, ROLE_STUDENT, ROLE_TOTEM } from "../constants/roles";
import { SITE_CHARGE_FEES, SITE_INSCRIPTIONS, SITE_LOGIN, SITE_SUBJECTS, SITE_TAKE_ATTENDANCE_AUTOMATICALLY } from "../constants/sites";

export function nf( int, digit) {
  if( int === undefined || int == null ) return '';
  let intString = int.toString();
  if (intString.length >= digit) return intString;
  const diff = digit - intString.length;
  for (let i = 0; i < diff; i++) intString = '0' + intString;
  return intString;
}

export function formatDateFull( date ) {
  try{
    const [ year, month, day ] = date.split('-');
    return `${day}/${month}/${year}`;
  }catch(e){
    return undefined;
  }
}

export function formatDateMonthAndYear( date ) {
  try{
    const [ year, month, day ] = date.split('-');
    return `${month}/${year}`;
  }catch(e){
    return undefined;
  }
}

export function formatTimestampDateFull( timestamp ) {
  try {
    const[ date, time ] = timestamp.split(' ');
    return formatDateFull( date );
  }catch(e){
    return undefined;
  }
}

export function dayNumberToString( day_number ){
  switch( day_number ){
    case 0:
      return 'Domingo';
    case 1:
      return 'Lunes';
    case 2:
      return 'Martes';
    case 3:
      return 'Miercoles';
    case 4:
      return 'Jueves';
    case 5:
      return 'Viernes';
    case 6:
      return 'Sabado';
  }
}

export const refreshUser = ( user, site, dispatch ) => {
  if( !user.started_state ){
    const userLs = localStorage.getItem( LS_USER );
    if( userLs ){ 
      dispatch( saveUser( JSON.parse( userLs ) ));
      refreshSite( userLs, site, dispatch );
    }
  }
}

export const updateSite = ( dispatch, site, newSite ) => {
  // Obtenemos el site desde Redux que nos indica donde esta posicionado el usuario
  dispatch( saveSiteHistory( site.site ) );
  dispatch( saveSite( newSite ) );
}

export const refreshSite = ( user, site, dispatch ) => {
  if( !site.started_state ){
    user = JSON.parse(user);
    switch( user.role_id ){
      case ROLE_UNDEFINED:
        dispatch( saveSite( SITE_LOGIN ) );
        break;
      case ROLE_ROOT:
        dispatch( saveSite( SITE_SUBJECTS ) );
        break;
      case ROLE_ADMIN:
        dispatch( saveSite( SITE_SUBJECTS ) );
        break;
      case ROLE_RECEPTIONIST:
        dispatch( saveSite( SITE_SUBJECTS ) );
        break;
      case ROLE_STUDENT:
        dispatch( saveSite( SITE_INSCRIPTIONS ) );
        break;
      case ROLE_TOTEM:
        dispatch( saveSite( SITE_TAKE_ATTENDANCE_AUTOMATICALLY ) );
        break;
    }
  }
}