export const SITE_LOGIN = 'LOGIN';
export const SITE_REGISTER_STUDENT = 'REGISTER_STUDENT';
export const SITE_REGISTER_RECEPTIONIST = 'REGISTER_RECEPTIONIST';

export const SITE_MY_PROFILE = 'MY_PROFILE';
export const SITE_ANOTHER_PERSON_PROFILE = 'ANOTHER_PERSON_PROFILE';

export const SITE_SUBJECTS = 'SUBJECTS';
export const SITE_SCHOOL_YEARS = 'SCHOOL_YEARS';
export const SITE_NEW_SCHOOL_YEAR = 'NEW_SCHOOL_YEAR';

export const SITE_LESSONS = 'LESSONS';

export const SITE_ATTENDANCE_AT_LESSON = 'ATTENDANCE_AT_LESSON';

export const SITE_MONTHLY_FEES = 'MONTHLY_FEES';

export const SITE_RECEPTIONISTS = 'RECEPTIONISTS';

export const SITE_STUDENTS = 'STUDENTS';

export const SITE_TUITIONS = 'TUITIONS';

export const SITE_CHARGE_FEES = 'CHARGE_FEES';

export const SITE_INSCRIPTIONS = 'INSCRIPTIONS';
export const SITE_INSCRIPTION_SCHOOL_YEAR = 'INSCRIPTIONS_SCHOOL_YEAR';
export const SITE_SCHOOL_YEARS_STUDYING = 'SCHOOL_YEARS_STUDYING';

export const SITE_GET_QR = 'GET_QR';

export const SITE_TAKE_ATTENDANCE_AUTOMATICALLY = 'TAKE_ATTENDANCE_AUTOMATICALLY';