import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { LS_USER } from '../../../helpers/storage';
import { ROUTE_ATTENDANCE_AT_LESSON_TAKE_ATTENDACE_AUTOMATICALLY, ROUTE_INSCRIPTION_SHOW_AND_CHECK_DEBT } from '../../../api-rest/back-cfp';
import { nf } from '../../../helpers/functions';
import { simpleRunFetch } from '../../../helpers/fetch';
import { days } from '../../../constants/days';
import logo800 from '../../../assets/logo_cfp_800.png';
import soundAlert from '../../../assets/alert.mp3'

import './TakeAttendanceAutomatically.css';
import { id } from 'date-fns/locale';

export const TakeAttendanceAutomatically = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;

  const [ QRValue, setQRValue ] =  useState(null);
  const [ invalidQR, setInvalidQR ] = useState(false);
  const [ QRValueCopy, setQRValueCopy ] =  useState(null);
  const [ attendances, setAttendances ] = useState( [] );
  const [ inscriptionsWithDebt, setInscriptionsWithDebt ] = useState( [] );
  const [ idTimeout, setIdTimeout ] = useState( null );

  useEffect( () => {
    if( idTimeout !== null ) return;
    setQRValueCopy( QRValue );
    takeAttendanceByQR();
  }, [QRValue]);

  const getTakeAttendanceAutomatically = async (user_id) => {
    const dateNow = new Date();
    const date = `${dateNow.getFullYear()}-${nf(dateNow.getMonth()+1,2)}-${nf(dateNow.getDate(),2)}`;

    const body = {
      user_id,
      date,
      hour: dateNow.getHours(),
      minute: dateNow.getMinutes()
    };

    try{
      const responseTakeAttendanceAutomatically = await simpleRunFetch( ROUTE_ATTENDANCE_AT_LESSON_TAKE_ATTENDACE_AUTOMATICALLY, body, authorization );
      // console.log( 'responseTakeAttendanceAutomatically', JSON.stringify( responseTakeAttendanceAutomatically, null, 4 ) );
      return responseTakeAttendanceAutomatically?.attendances;
    }catch(e){
      console.log('-> ', e);
      return false;
    }
  }

  const getInscriptionsWithDebt = async (user_id) => {

    const date = new Date();
    const today_date = `${date.getFullYear()}-${nf(date.getMonth()+1,2)}-${nf(date.getDate(),2)}`;

    const route = [...ROUTE_INSCRIPTION_SHOW_AND_CHECK_DEBT];
    route[0] = route[0].replace( '{user_id}', user_id ).replace( '{today_date}', today_date );
     
    let response;
    try{ 
      response = await simpleRunFetch( route, null, authorization );
      
      // Filtramos solo las inscripciones que tienen deuda
      response = response.filter( inscription => 
        inscription.monthlyFees > 0
      );
      // console.log( 'response inscriptions with debt:', JSON.stringify( response, null, 4 ) );
      return response;
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const webcamReadQR = ( result, error ) => {
    if (!!result) {
      // const currentDate = new Date();
      // const deltaMillisReadQR = Math.abs( currentDate - dateReadQR );
      // console.log( 'deltaMillisReadQR', deltaMillisReadQR );
      
      // dateReadQR = currentDate;
      // if( deltaMillisReadQR < TOLERANCE_MILLIS_READ_QR ) return;
      // console.log('paso');

      try{
        const resultJson = JSON.parse(result?.text);
        if(
          resultJson.user_id &&
          resultJson.role_id &&
          resultJson.name &&
          resultJson.last_name
        ){
          setQRValue( resultJson );
        } else{
          console.log('El QR no es valido.');
          setQRValue( null );
        }
      }catch(e){
        console.info(e);
        console.log('Fallo el parseo del QR');
        setQRValue( null );
      }
    }
    if (!!error) {
      // console.info(error);
      setInvalidQR(false);
    }
  }

  const takeAttendanceByQR = async () => {
    if( !QRValue ){
      setInvalidQR(true);
      return;
    }
    
    // const audio = new Audio('alert_glassy_mallet_001.mp3');
    const audio = new Audio(soundAlert);
    audio.play();
    setAttendances( await getTakeAttendanceAutomatically( QRValue.user_id ) );
    setInscriptionsWithDebt( await getInscriptionsWithDebt( QRValue.user_id ) );

    clearTimeout( idTimeout );
    setIdTimeout( 
      setTimeout(()=>{
        setAttendances(null);
        setInscriptionsWithDebt(null);
        setIdTimeout(null);
      }, 7000 )
    );
  }

  const getDayName = ( attendance ) => {
    return days.filter( element => element.numberId === attendance.lesson?.lesson_schedule?.day_number)
    .map( element => {
      return element.name
    })
  }

  const getHourRange = ( attendance ) => {
    const lesson_schedule = attendance?.lesson?.lesson_schedule;
    return `${ nf(lesson_schedule?.start_time,2) }:${ nf(lesson_schedule?.start_minute,2) } a ${ nf(lesson_schedule?.end_time,2) }:${ nf(lesson_schedule?.end_minute,2) } hs.`;
  }

  return (
    <>
      <div className='title'>
        <h1>Tomar asistencia por QR</h1>
      </div>
      <div className='layoutTakeAttendanceAutomatically'>
        <div className='col1'>
          {/* <div> */}
          {/* <div style={{width: '40%'}}> */}
          <div style={{width: '100%'}}>
            <QrReader
              scanDelay='1000'
              onResult={webcamReadQR}
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div className='col2 centerImage'>
          <img src={logo800}></img>
        </div>
        <div className='col3'>

          {
            QRValue && QRValueCopy && attendances &&
            <h2><strong>{QRValueCopy.name} {QRValueCopy.last_name}</strong></h2>
          }

          {
            QRValue && attendances && attendances.length === 0 &&
            <h3>No se encontraron carreras para tomar asistencia.</h3>
          }

          {
            QRValue && attendances && attendances.length > 0 &&
            <>
              <h3>¡Excelente! Se tomó asistencia para las carreras:</h3>
              <ul>
                {
                  attendances.map( attendance => (
                    <li>
                      {attendance.lesson?.school_year?.subject?.name} - {getDayName( attendance )} {getHourRange( attendance )}
                    </li>
                  ))
                }
              </ul>
            </>
          }
          {
            QRValue && inscriptionsWithDebt && inscriptionsWithDebt.length > 0 &&
            <div className='error'>
              <br />
              <h2>Adeuda cuotas de:</h2>
              <ul>
                {
                  inscriptionsWithDebt.map( inscription => (
                    <li>
                      {inscription?.school_year?.subject?.name}
                    </li>
                  ))
                }
              </ul>
            </div>
          }
        </div>
      </div>
    </>
  )
}
