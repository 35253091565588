const BASE = 'https://cfpdardorocha.com.ar/api/api'; //'http://127.0.0.1:8000/api'; //'http://sd-3112420-h00001.ferozo.net/api/api';

export const ROUTE_LOGIN = [ BASE + '/user/login', 'POST' ];

/***************** USER ****************/

export const ROUTE_USER_REGISTER = [ BASE + '/user/register', 'POST' ];
export const ROUTE_USER_INFO = [ BASE + '/user/info', 'GET' ];
export const ROUTE_USER_UPDATE_MY_PERSONAL_DATA = [ BASE + '/user/update-my-personal-data', 'POST' ];
export const ROUTE_USER_UPDATE_ANOTHER_PERSON_PERSONAL_DATA = [ BASE + '/user/update-another-person-personal-data/', 'POST' ];
export const ROUTE_USER_GET_ALL = [ BASE + '/user/get-all/', 'GET' ]; 
export const ROUTE_USER_SEARCH_BY_IDENTIFICATION_NUMBER = [ BASE + '/user/search-by-identification-number/', 'GET' ];
export const ROUTE_USER_CHANGE_PASSWORD = [ BASE + '/user/change-password', 'POST' ];
export const ROUTE_USER_CHANGE_PASSWORD_TO_ANOTHER_USER = [ BASE + '/user/change-password-to-another-user', 'POST' ];

/***************** TUITION ****************/

export const ROUTE_TUITION_SHOW = [ BASE + '/tuition/show', 'GET' ];
export const ROUTE_TUITION_CREATE = [ BASE + '/tuition/create', 'POST' ];
export const ROUTE_TUITION_DELETE = [ BASE + '/tuition/delete/{tuition_id}', 'DELETE' ];

/***************** SUBJECT ****************/

export const ROUTE_SUBJECT_SHOW = [ BASE + '/subject/show', 'GET' ];
export const ROUTE_SUBJECT_CREATE = [ BASE + '/subject/create', 'POST' ];
export const ROUTE_SUBJECT_RENAME = [ BASE + '/subject/rename', 'PATCH' ];
export const ROUTE_SUBJECT_DELETE = [ BASE + '/subject/delete', 'DELETE' ];

/***************** SCHOOL YEAR ****************/

export const ROUTE_SCHOOL_YEAR_SHOW = [ BASE + '/school-year/show', 'POST' ];
export const ROUTE_SCHOOL_YEAR_FEES_PAID_ON_TOTAL_FEES = [ BASE + '/school-year/fees-paid-on-total-fees/{school_year_id}', 'GET' ];
export const ROUTE_SCHOOL_YEAR_CREATE = [ BASE + '/school-year/create', 'POST' ];
export const ROUTE_SCHOOL_YEAR_OPEN_REGISTRATION = [ BASE + '/school-year/open-registration', 'PATCH' ];
export const ROUTE_SCHOOL_YEAR_DELETE = [ BASE + '/school-year/delete', 'DELETE' ];

/***************** LESSON ****************/

export const ROUTE_LESSON_SHOW = [ BASE + '/lesson/show/', 'GET' ];
export const ROUTE_LESSON_UPDATE_STATE = [ BASE + '/lesson/update-state', 'POST' ];

/***************** INSCRIPTION ****************/

export const ROUTE_INSCRIPTION_REGISTER = [ BASE + '/inscription/register', 'POST' ];
export const ROUTE_INSCRIPTION_STUDENTS = [ BASE + '/inscription/students/', 'GET' ];
export const ROUTE_INSCRIPTION_STUDENTS_AND_CHECK_DEBT = [ BASE + '/inscription/students/{school_year_id}/{today_date}', 'GET' ];
export const ROUTE_INSCRIPTION_SHOW = [ BASE + '/inscription/show', 'GET' ];
export const ROUTE_INSCRIPTION_SHOW_AND_CHECK_DEBT = [ BASE + '/inscription/show-and-check-debt/{user_id}/{today_date}', 'GET' ];
export const ROUTE_INSCRIPTION_DELETE = [ BASE + '/inscription/delete/', 'DELETE' ];

/***************** ATTENDANCE AT LESSON ****************/

export const ROUTE_ATTENDANCE_AT_LESSON_SHOW = [ BASE + '/attendance-at-lesson/show/', 'GET' ];
export const ROUTE_ATTENDANCE_AT_LESSON_TAKE_ATTENDACE = [ BASE + '/attendance-at-lesson/take-attendance', 'PATCH' ];
export const ROUTE_ATTENDANCE_AT_LESSON_TAKE_ATTENDACE_AUTOMATICALLY = [ BASE + '/attendance-at-lesson/take-attendance-automatically', 'PATCH' ];

/***************** MONTHLY FEES ****************/

export const ROUTE_MONTHLY_FEES_CHECK_DEBT = [ BASE + '/monthly-fees/check-debt', 'POST' ];
export const ROUTE_MONTHLY_FEES_SHOW = [ BASE + '/monthly-fees/show/', 'GET' ];
export const ROUTE_MONTHLY_FEES_SHOW_MY = [ BASE + '/monthly-fees/show/my/', 'GET' ];
export const ROUTE_MONTHLY_FEES_TAKE_FEE = [ BASE + '/monthly-fees/take-fee', 'PATCH' ];
