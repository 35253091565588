import React from 'react';
import { useSelector } from 'react-redux';
import { QRCodeSVG, QRCodeCanvas } from 'qrcode.react';

export const GetQR = () => {

  // Obtenemos el usuario desde Redux
  const user = useSelector( state => state.user );
  const dataForQR = {
    user_id: user.user_id,
    role_id: user.role_id,
    name: user.name,
    last_name: user.last_name
  };

  return (
    <div>
      <h1>Obtener QR</h1>
      <QRCodeCanvas 
      value={JSON.stringify(dataForQR)} 
      size='300'
      includeMargin={true}
      />
    </div>
  )
}
