import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import siteReducer from '../features/site/siteSlice';
import siteHistoryReducer from '../features/siteHistory/siteHistorySlice';
import subjectReducer from '../features/subject/subjectSlice';
import schoolYearReducer from '../features/schoolYear/schoolYearSlice';
import lessonReducer from '../features/lesson/lessonSlice';
import inscriptionReducer from '../features/inscription/inscriptionSlice';
import anotherPersonUserReducer from '../features/anotherPersonUser/anotherPersonUserSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    site: siteReducer,
    siteHistory: siteHistoryReducer,
    subject: subjectReducer,
    schoolYear: schoolYearReducer,
    lesson: lessonReducer,
    inscription: inscriptionReducer,
    anotherPersonUser: anotherPersonUserReducer,
  },
});