import { useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { ROUTE_SCHOOL_YEAR_SHOW, ROUTE_INSCRIPTION_REGISTER } from "../../../api-rest/back-cfp";
import { nf, dayNumberToString, formatDateFull } from '../../../helpers/functions';
import { LS_USER } from '../../../helpers/storage';
import { simpleRunFetch } from '../../../helpers/fetch';
import { useDispatch } from "react-redux";
import { saveSite } from "../../../features/site/siteSlice";
import { SITE_NEW_SCHOOL_YEAR, SITE_LESSONS } from "../../../constants/sites";
import { saveSchoolYear } from "../../../features/schoolYear/schoolYearSlice";
import Modal from 'react-modal';
import { ComeBack } from "../ComeBack/ComeBack";

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const InscriptionSchoolYear = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Obtenemos el subject desde Redux
  const subject = useSelector( state => state.subject );
  const [ schoolYears, setSchoolYears ] = useState( [] );
  const [ schoolYearSelect, setSchoolYearSelect ] = useState( undefined );
  const [ modalInscriptionIsOpen, setModalInscriptionIsOpen ] = useState(false);
  const [ modalInscriptionResponse, setModalInscriptionResponse ] = useState(false);
  const [ titleInscriptionResponse, setTitleInscriptionResponse ] = useState('');
  const [ messageInscriptionResponse, setMessageInscriptionResponse ] = useState('');

  const [ disabled, setDisabled ] = useState( false );

  useEffect( () => {
    getSchoolYears();
  }, [] );

   // Metodo para pedir los ciclos
   const getSchoolYears = async () => {
    
    // Construimos el body
    const body = {
      subject_id: subject.id,
      open_registration: true
    }
    const date = new Date();
    body['today_date'] = `${date.getFullYear()}-${nf(date.getMonth()+1,2)}-${nf(date.getDate(),2)}`;

    let response;
    try{ 
      response = await simpleRunFetch( ROUTE_SCHOOL_YEAR_SHOW, body, authorization );
      setSchoolYears( response.school_years );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  };

  const clickInscription = (e, schoolYear) => {
    setSchoolYearSelect( schoolYear );
    setModalInscriptionResponse( false );
    setModalInscriptionIsOpen( true );
  }

  const afterOpenModalInscriptionIsOpen = () => {
    // Potencialmente usable
  }

  const closeModalInscription = () => {
    getSchoolYears();
    setModalInscriptionIsOpen(false);
  }

  const createInscription = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador
    
    // Verificamos que se haya completado el formulario
    if( !e.target.voucher.value ) return;
    
    setDisabled( true );

    const body = {
      school_year_id: schoolYearSelect.id,
      voucher: e.target.voucher.value.trim()
    }

    let response;
    try{ 
      response = await simpleRunFetch( ROUTE_INSCRIPTION_REGISTER, body, authorization );
      setTitleInscriptionResponse( '¡Excelente!' );
      setMessageInscriptionResponse( 'Te has inscripto correctamente.' );
    }catch(e){
      console.log('-> ', e);
      setTitleInscriptionResponse( 'Lo sentimos, hubo un error' );
      setMessageInscriptionResponse( 'Vuelva a intentarlo nuevamente, o comuniquese con la administración.' );
    }

    setModalInscriptionResponse(true);
    setDisabled( false );
  }

  // Metodos del modal 'NewSubject'
  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  return (
    <div>
      <ComeBack />
      <div className='title'>
        <h1>{ subject.name }</h1>
      </div>
      <div>
          <h3>Ciclos de la carrera:</h3>
      </div>
      <div>
        <Modal
          isOpen={modalInscriptionIsOpen}
          onAfterOpen={afterOpenModalInscriptionIsOpen}
          onRequestClose={closeModalInscription}
          style={modalStyles}
          ariaHideApp={false}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          contentLabel="Modal para inscribirse a un ciclo"
        >
          { !modalInscriptionResponse
            ?
              <div>
                <h2>Inscribirse al ciclo</h2>
                <p>Para inscribirse debe ingresar el <strong>comprobante</strong> que le proporcionó la administración:</p>
                <form onSubmit={createInscription}>
                  <input type='text' id='voucher' placeholder='Comprobante' /><br/><br/>
                  <input className='confirmationButton' type={'submit'} name='send' value={'Inscribirse'} disabled={disabled} />&nbsp;
                  <button className='cancelButton' onClick={closeModalInscription} disabled={disabled}>Cancelar</button>
                </form>
              </div>
            :
              <div>
                <h2>{titleInscriptionResponse}</h2>
                <p>{messageInscriptionResponse}</p>
                <button onClick={closeModalInscription}>Aceptar</button>
              </div>
          }
          
        </Modal>
      </div>
      <div>
        <table className='tableForList' >
          <tr className='oddRow'>
            <th className='column'>Inicio del ciclo</th>
            <th className='column'>Final del ciclo</th>
            <th className='column'>Aula</th>
            <th className='column'>Cupo</th>
            <th className='column'>Horario</th>
            <th className='column'>Inscribirse</th>
          </tr>
          { 
            schoolYears.map( (schoolYear, index) => {

              let lessonSchedules = schoolYear.lesson_schedule?.map( e => {
                return `${ dayNumberToString( e.day_number ) } de ${nf(e.start_time,2)}:${nf(e.start_minute,2)} a ${nf(e.end_time,2)}:${nf(e.end_minute,2)} hs.`;
              });
              lessonSchedules = lessonSchedules.join('<br/>');

              const disableIncription = schoolYear.quota && schoolYear.quota <= schoolYear.inscription.length? true : false;

              return (
                <tr className={getClassForRow(index)}>
                  <td className='column'>{ formatDateFull( schoolYear.start_date ) }</td>
                  <td className='column'>{ formatDateFull( schoolYear.end_date ) }</td>
                  <td className='column'>{schoolYear.classroom}</td>
                  <td className='column'>{schoolYear.inscription.length}/{schoolYear.quota? schoolYear.quota : '∞'}</td>
                  <td className='column' dangerouslySetInnerHTML={{__html: lessonSchedules}}></td>
                  <td className='column'><button disabled={disableIncription} onClick={ e => clickInscription(e, schoolYear) }>Inscribirse</button></td>
                </tr>
              );
            }) 
          }
        </table>
      </div>
    </div>
  )
}
