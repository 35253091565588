import { useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { ROUTE_SCHOOL_YEAR_DELETE, ROUTE_SCHOOL_YEAR_OPEN_REGISTRATION, ROUTE_SCHOOL_YEAR_SHOW } from "../../../api-rest/back-cfp";
import { nf, dayNumberToString, updateSite, formatDateFull } from '../../../helpers/functions';
import { LS_USER } from '../../../helpers/storage';
import { simpleRunFetch } from '../../../helpers/fetch';
import { useDispatch } from "react-redux";
import { SITE_NEW_SCHOOL_YEAR, SITE_LESSONS } from "../../../constants/sites";
import { saveSchoolYear } from "../../../features/schoolYear/schoolYearSlice";
import { ComeBack } from "../ComeBack/ComeBack";
import { ROLE_ADMIN, ROLE_ROOT } from "../../../constants/roles";
import { PasswordInput } from "../../PasswordInput/PasswordInput";
import { FeesPaidOnTotalFees } from "./FeesPaidOnTotalFees/FeesPaidOnTotalFees";
import Modal from 'react-modal';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const SchoolYears = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Obtenemos el subject desde Redux
  const subject = useSelector( state => state.subject );
  const [ schoolYears, setSchoolYears ] = useState( [] );
  const [ showExpiredCycles, setShowExpiredCycles ] = useState( false );

  // Eliminar un ciclo
  const [ schoolYearId, setSchoolYearId ] = useState( null );
  const [ modalDeleteSchoolYearIsOpen, setModalDeleteSchoolYearIsOpen ] = useState(false);
  const [ errorDeleteSchoolYear, setErrorDeleteSchoolYear ] = useState( false );

  // Estado de los buttons
  const [ disabled, setDisabled ] = useState( false );

  useEffect( () => {
    getSchoolYears();
  }, [] );

  useEffect( () => {
    getSchoolYears();
  }, [showExpiredCycles] );

  const changeShowExpiredCycles = (e) => {
    setShowExpiredCycles( e.target.checked );
  }

   // Metodo para pedir los ciclos
   const getSchoolYears = async () => {
    
    // Construimos el body
    const body = {
      subject_id: subject.id
    }
    if( !showExpiredCycles ){
      const date = new Date();
      body['today_date'] = `${date.getFullYear()}-${nf(date.getMonth()+1,2)}-${nf(date.getDate(),2)}`;
    }

    let response;
    try{ 
      response = await simpleRunFetch( ROUTE_SCHOOL_YEAR_SHOW, body, authorization );
      setSchoolYears( response.school_years );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  };

  // Metodos del modal 'NewSubject'
  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  const clickNewSchoolYear = () => {
    updateSite( dispatch, site, SITE_NEW_SCHOOL_YEAR );
  }

  const clickSchoolYear = ( e, schoolYear ) => {
    dispatch( saveSchoolYear( schoolYear ));
    updateSite( dispatch, site, SITE_LESSONS );
  }

  const changeOpenRegistration = async (e, schoolYear) => {
    const body = {
      school_year_id: schoolYear.id,
      open_registration: e.target.checked
    };

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_SCHOOL_YEAR_OPEN_REGISTRATION, body, authorization );
      await getSchoolYears();
    }catch(e){
      console.log('-> ', e);
    }
  }

  const clickDeleteSchoolYear = ( e, schoolYear ) => {
    setSchoolYearId( schoolYear.id );
    openModalDeleteSchoolYear();
  }

  const openModalDeleteSchoolYear = () => {
    setModalDeleteSchoolYearIsOpen( true );
    setErrorDeleteSchoolYear(false);
  }

  const closeModalDeleteSchoolYear = () => {
    setModalDeleteSchoolYearIsOpen( false );
  }

  const deleteSchoolYear = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    setDisabled( true );

    const body = {
      current_password: e.target.current_password.value,
      school_year_id: schoolYearId
    };

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_SCHOOL_YEAR_DELETE, body, authorization );
      await getSchoolYears();
      closeModalDeleteSchoolYear()
    }catch(e){
      console.log('-> ', e);
      setErrorDeleteSchoolYear( true );
    }

    setDisabled( false );
  }

  return (
    <div>
      <ComeBack />
      <div className='title'>
        <h1>{ subject.name }</h1>
      </div>
      <div>
        {
          user.role_id === ROLE_ADMIN &&
          <button onClick={clickNewSchoolYear}>Nuevo ciclo</button>
        }
      </div>
      <div>
          <h3>Ciclos de la carrera:</h3>
          <input type="checkbox" id="cbox" checked={showExpiredCycles} onChange={changeShowExpiredCycles} /> <label for="cbox">Mostrar ciclos finalizados</label>
      </div>
      <div>
        <table className='tableForList' >
          <tr className='oddRow'>
            <th className='column'>Inicio del ciclo</th>
            <th className='column'>Final del ciclo</th>
            <th className='column'>Aula</th>
            <th className='column'>Cupo</th>
            <th className='column'>Horario</th>
            {
              (user.role_id === ROLE_ROOT || user.role_id === ROLE_ADMIN) &&
              <>
                <th className='column'>Inscripción abierta</th>
                <th className='column'>Pagos/TOTAL</th>
              </>
            }
            <th className='column'>Planilla</th>
            {
              (user.role_id === ROLE_ROOT || user.role_id === ROLE_ADMIN) &&
              <th className='column'>Eliminar</th>
            }
          </tr>
          { 
            schoolYears.map( (schoolYear, index) => {

              // const hours = schoolYear.lesson_schedule?.reduce( (previousValue, currentValue) => {
              //   return previousValue.day_number + ' -> ' + currentValue.day_number;
              // });

              let lessonSchedules = schoolYear.lesson_schedule?.map( e => {
                return `${ dayNumberToString( e.day_number ) } de ${nf(e.start_time,2)}:${nf(e.start_minute,2)} a ${nf(e.end_time,2)}:${nf(e.end_minute,2)} hs.`;
              });
              lessonSchedules = lessonSchedules.join('<br/>');

              return (
                <tr className={getClassForRow(index)}>
                  <td className='column'>{ formatDateFull( schoolYear.start_date ) }</td>
                  <td className='column'>{ formatDateFull( schoolYear.end_date ) }</td>
                  <td className='column'>{schoolYear.classroom}</td>
                  <td className='column'>{schoolYear.inscription.length}/{schoolYear.quota? schoolYear.quota : '∞'}</td>
                  <td className='column' dangerouslySetInnerHTML={{__html: lessonSchedules}}></td>
                  {
                    (user.role_id === ROLE_ROOT || user.role_id === ROLE_ADMIN) &&
                    <>
                      <td className='column'><input type="checkbox" id="cboxOpenRegistration" checked={schoolYear.open_registration} onChange={ e => changeOpenRegistration(e, schoolYear) } /></td>
                      <td className='column'><FeesPaidOnTotalFees schoolYearId={schoolYear.id} /></td>
                    </>
                  }
                  <td className='column'><button onClick={ e => clickSchoolYear(e, schoolYear) }>Planilla</button></td>
                  {
                    (user.role_id === ROLE_ROOT || user.role_id === ROLE_ADMIN) &&
                    <td className='column'><button onClick={ e => clickDeleteSchoolYear(e, schoolYear) }>Eliminar</button></td>
                  }
                </tr>
              );
            }) 
          }
        </table>

        <Modal
          isOpen={modalDeleteSchoolYearIsOpen}
          // onAfterOpen={afterOpenModalInscriptionIsOpen}
          onRequestClose={closeModalDeleteSchoolYear}
          style={modalStyles}
          ariaHideApp={false}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          contentLabel="Modal para eliminar un cliclo lectivo"
        >

          <h2>Eliminar ciclo lectivo</h2>
          <p>Esta a punto de eliminar un ciclo lectivo, esta opración no se puede deshacer.</p>
          <p>Por seguridad, para eliminar el ciclo debe introducir su contraseña.</p>
          <form onSubmit={deleteSchoolYear} >
            
            <PasswordInput label={'Contraseña actual'} name={'current_password'} />
            <br /><br />

            <button onClick={closeModalDeleteSchoolYear} type={'button'} disabled={disabled} >Cancelar</button>&nbsp;
            <input type={'submit'} name='send' value={'Confirmar'} disabled={disabled} />
          </form>

          {
            errorDeleteSchoolYear &&
            <>
              <h4 style={{color:'red'}}>No se pudo eliminar el ciclo</h4>
              <p>  
                Es posible que la contraseña no sea correcta.
              </p>
            </>
          }
        </Modal>

      </div>
    </div>
  )
}
