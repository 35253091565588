import React from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../features/site/siteSlice";
import { updateSiteHistory } from '../../../features/siteHistory/siteHistorySlice';

export const ComeBack = () => {

  const siteHistory = useSelector( state => state.siteHistory );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  const clickComeBack = () => {
    // Casteamos a array el historico de sitios
    const arraySiteHistory = Array.from( siteHistory.site );
    // Le quitamos al array el ultimo de sus elementos y lo guardamos en la variable siteBack
    const siteBack = arraySiteHistory.pop();
    // Guardamos el nuevo array de siteHistory
    dispatch( updateSiteHistory( arraySiteHistory ) );
    // Actualizamos el nuevo site
    dispatch( saveSite( siteBack ) );
  }

  return (
    <div><a onClick={ clickComeBack }>⬅️ Atrás</a></div>
  )
}
