import React from 'react';
import { ROLE_STUDENT } from '../../../constants/roles';
import { Register } from '../../Register/Register';

export const RegisterStudent = () => {

  return (
    <Register 
      role_id={ ROLE_STUDENT }
      title={ 'Registrarse' }
      submitValue={ 'Registrarse' }
      successMessage={ "Te has registrado correctamente, por favor dirigase a 'Inicir sesión'." }
    />
  )
}
