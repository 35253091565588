import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { LS_USER } from '../../../helpers/storage';
import { ROUTE_USER_GET_ALL } from "../../../api-rest/back-cfp";
import { ROLE_RECEPTIONIST } from "../../../constants/roles";
import { simpleRunFetch } from "../../../helpers/fetch";
import { formatDateFull } from "../../../helpers/functions";
import { saveAnotherPersonUser } from "../../../features/anotherPersonUser/anotherPersonUserSlice";
import { updateSite } from "../../../helpers/functions";
import { SITE_ANOTHER_PERSON_PROFILE, SITE_REGISTER_RECEPTIONIST } from "../../../constants/sites";

export const Receptionists = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  const [ receptionists, setReceptionists ] = useState([]);

  useEffect( () => {
    getReceptionists();
  }, []);

  const getReceptionists = async () => {
    
    const route = [...ROUTE_USER_GET_ALL];
    route[0] += ROLE_RECEPTIONIST;
    
    try{
      const response = await simpleRunFetch( route, null, authorization );
      setReceptionists( response )
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const goProfile = ( receptionist ) => {
    dispatch( saveAnotherPersonUser( receptionist ) );
    updateSite( dispatch, site, SITE_ANOTHER_PERSON_PROFILE );
  }

  const goRegisterRecepcionist = () => {
    updateSite( dispatch, site, SITE_REGISTER_RECEPTIONIST );
  }

  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  return (
    <div>
      <div className='title'>
        <h1>Recepcionistas</h1>
        <button onClick={goRegisterRecepcionist}>Registrar nuevo recepcionista</button>
      </div>
      <table className='tableForList' >
        <tr className='oddRow'>
          <th className='column'>Apellidos</th>
          <th className='column'>Nombres</th>
          <th className='column'>DNI</th>
          <th className='column'>Email</th>
          <th className='column'>Celular</th>
          <th className='column'>Fecha de nacimiento</th>
          <th className='column'>Localidad actual</th>
          <th className='column'>Domicilio actual</th>
          <th className='column'>Perfil</th>
        </tr>
        {
          receptionists &&
          receptionists.map( (receptionist, index) => {
            return (
              <tr className={getClassForRow(index)}>
                <td className='column'>{receptionist.last_name}</td>
                <td className='column'>{receptionist.name}</td>
                <td className='column'>{receptionist.identification_number}</td>
                <td className='column'>{receptionist.email}</td>
                <td className='column'>{receptionist.cellphone_number}</td>
                <td className='column'>{ formatDateFull( receptionist.date_of_birth ) }</td>
                <td className='column'>{receptionist.location}</td>
                <td className='column'>{receptionist.domicile}</td>
                <td className='column'><button onClick={ e => goProfile(receptionist) } >Perfil</button></td>
              </tr>
            )
          })
        }
      </table>

    </div>
  )
}
