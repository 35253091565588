import { createSlice } from '@reduxjs/toolkit'
import { SITE_LOGIN } from '../../constants/sites';

const initialState = {
  started_state: false,
  site: [SITE_LOGIN],
}

export const siteHistorySlice = createSlice({
  name: 'siteHistory',
  initialState,
  reducers: {
    saveSiteHistory: ( state, action ) => {
      state.started_state = true;
      state.site.push(action.payload);
    },
    updateSiteHistory: (state, action) => {
      state.site = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveSiteHistory, updateSiteHistory } = siteHistorySlice.actions

export default siteHistorySlice.reducer