import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  started_state: false,
  id: undefined,
}

export const anotherPersonUserSlice = createSlice({
  name: 'anotherPersonUser',
  initialState,
  reducers: {
    saveAnotherPersonUser: ( state, action ) => {
      state.started_state = true;
      state.id            = action.payload.id;
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveAnotherPersonUser } = anotherPersonUserSlice.actions

export default anotherPersonUserSlice.reducer