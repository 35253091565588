import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { simpleRunFetch } from '../../../helpers/fetch';
import { ROUTE_SUBJECT_CREATE, ROUTE_SUBJECT_DELETE, ROUTE_SUBJECT_RENAME, ROUTE_SUBJECT_SHOW } from '../../../api-rest/back-cfp';
import { LS_USER } from '../../../helpers/storage';
import Modal from 'react-modal';
import { saveSubject } from "../../../features/subject/subjectSlice";
import { saveSite } from "../../../features/site/siteSlice";
import { SITE_SCHOOL_YEARS } from "../../../constants/sites";
import { updateSite } from "../../../helpers/functions";
import { ROLE_ADMIN } from "../../../constants/roles";
import { PasswordInput } from "../../PasswordInput/PasswordInput";

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const Subjects = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  const [ subjects, setSubjects ] = useState( [] );
  const [modalNewSubjectIsOpen, setModalNewSubjectIsOpen] = useState(false);

  // Edicion y eliminacion de una carrera
  const [ aSubject, setASubject ] = useState( null );
  const [ modalRenameSubjectIsOpen, setModalRenameSubjectIsOpen ] = useState(false);
  const [ modalDeleteSubjectIsOpen, setModalDeleteSubjectIsOpen ] = useState(false);
  const [ errorFetch, setErrorFetch ] = useState( false );

  // Estado de los buttons
  const [ disabled, setDisabled ] = useState( false );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Metodo para pedir las carreras
  const getSubjects = async () => {
    let subjectsAux;
    try{ 
      subjectsAux = await simpleRunFetch( ROUTE_SUBJECT_SHOW, null, authorization );
      console.log( 'subjectsAux', JSON.stringify( subjectsAux, null, 4 ) );
      setSubjects( subjectsAux.subjects );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  };
   
  // UseEffect a modo 'Constructor' para pedir cargar las carreras
  useEffect( () => {
    getSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] );

  // Metodos del modal 'NewSubject'
  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  const openModalNewSubject = () => {
    setModalNewSubjectIsOpen(true);
  }

  const afterOpenModalNewSubject = () => {
    // potencialmente usable
  }

  const closeModalNewSubject = () => {
    setModalNewSubjectIsOpen(false);
  }

  const createNewSubject = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador
    
    // Verificamos que se haya completado el formulario
    if( !e.target.subjectName.value ) return;

    // Creamos el body para la peticion
    const body = {
      name: e.target.subjectName.value
    }

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_SUBJECT_CREATE, body, authorization );
    }catch(e){
      console.log('-> ', e);
    }

    getSubjects();
    setModalNewSubjectIsOpen(false);
  }

  // Boton entrar a carrera
  const enterSubject = (event, element) => {
    dispatch( saveSubject( element ));
    updateSite( dispatch, site, SITE_SCHOOL_YEARS );
  }

  const clickRenameSubject = ( e, subject ) => {
    setASubject( subject );
    openModalRenameSubject();
  }

  const openModalRenameSubject = () => {
    setModalRenameSubjectIsOpen( true );
    setErrorFetch(false);
  }

  const closeModalRenameSubject = () => {
    setModalRenameSubjectIsOpen( false );
  }

  const renameSubject = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    setDisabled( true );

    const body = {
      subject_id: aSubject.id,
      name: e.target.name.value
    };

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_SUBJECT_RENAME, body, authorization );
      await getSubjects();
      closeModalRenameSubject();
    }catch(e){
      console.log('-> ', e);
      setErrorFetch( true );
    }

    setDisabled( false );
  }

  const clickDeleteSubject = ( e, subject ) => {
    setASubject( subject );
    openModalDeleteSubject();
  }

  const openModalDeleteSubject = () => {
    setModalDeleteSubjectIsOpen( true );
    setErrorFetch(false);
  }

  const closeModalDeleteSubject = () => {
    setModalDeleteSubjectIsOpen( false );
  }

  const deleteSubject = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    setDisabled( true );

    const body = {
      current_password: e.target.current_password.value,
      subject_id: aSubject.id
    };

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_SUBJECT_DELETE, body, authorization );
      await getSubjects();
      closeModalDeleteSubject();
    }catch(e){
      console.log('-> ', e);
      setErrorFetch( true );
    }

    setDisabled( false );
  }

  return (
    <div>
      <div className='title'>
        <h1>Carreras</h1>
      </div>
      <div>
        {
          user.role_id === ROLE_ADMIN &&
          <button onClick={openModalNewSubject}>Nueva carrera</button>
        }
        <Modal
          isOpen={modalNewSubjectIsOpen}
          onAfterOpen={afterOpenModalNewSubject}
          onRequestClose={closeModalNewSubject}
          style={modalStyles}
          ariaHideApp={false}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          contentLabel="Modal para crear una nueva carrera"
        >
          <div>
            <h2>Crear nueva carrera</h2>
            <p>Introduzca el nombre de la carrera</p>
            <form onSubmit={createNewSubject}>
              <input type={'text'} name='subjectName' placeholder='Nombre de la carrera' ></input><br /><br />
              <input className='confirmationButton' type={'submit'} name='send' value={'Aceptar'} />&nbsp;
              <button className='cancelButton' onClick={closeModalNewSubject}>Cancelar</button>
            </form>
          </div>
        </Modal>
      </div>
      <div className='title'>
        <h3>Lista de carreras</h3>
      </div>
      <div>
        <table className='tableForList' >
          <tr className='oddRow'>
            <th className='column'>Nombre</th>
            <th className='column'>Ver ciclos</th>
            {
              user.role_id === ROLE_ADMIN &&
              <>
                <th className='column'>Editar</th>
                <th className='column'>Eliminar</th>
              </>
            }
          </tr>
          { 
            subjects.map( (subject, index) => {
              return (
                <tr className={getClassForRow(index)}>
                  <td className='column'>{subject.name}</td>
                  <td className='column'><button onClick={e => enterSubject(e, subject)}>Ver ciclos</button></td>
                  {
                    user.role_id === ROLE_ADMIN &&
                    <>
                      <td className='column'><button onClick={ e => clickRenameSubject(e, subject) } >Editar</button></td>
                      <td className='column'><button onClick={ e => clickDeleteSubject(e, subject) } >Eliminar</button></td>  
                    </>
                  }
                </tr>
              );
            }) 
          }
        </table>
      </div>

      <Modal
        isOpen={modalRenameSubjectIsOpen}
        // onAfterOpen={afterOpenModalInscriptionIsOpen}
        onRequestClose={closeModalRenameSubject}
        style={modalStyles}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Modal para modificar el nombre a una carrera"
      >
        {
          aSubject &&
          <h2>Renombrar '{aSubject.name}'</h2>
        }
        <form onSubmit={renameSubject}>
          
          <label>Nuevo nombre</label><br />
          <input type={'text'} name={'name'} ></input>
          <br /><br />

          <button onClick={closeModalRenameSubject} type={'button'} disabled={disabled} >Cancelar</button>&nbsp;
          <input type={'submit'} name='send' value={'Confirmar'} disabled={disabled} />
        </form>
        {
          errorFetch &&
          <h4 style={{color:'red'}}>No se pudo renombrar la carrera</h4>
        }
      </Modal>

      <Modal
        isOpen={modalDeleteSubjectIsOpen}
        // onAfterOpen={afterOpenModalInscriptionIsOpen}
        onRequestClose={closeModalDeleteSubject}
        style={modalStyles}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Modal para eliminar una carrera"
      >
        <h2>Eliminar carrera</h2>
        <p>Esta a punto de eliminar una carrera, esta opración no se puede deshacer.</p>
        <p>Por seguridad, para eliminar la carrera debe introducir su contraseña.</p>
        <form onSubmit={deleteSubject} >
          
          <PasswordInput label={'Contraseña actual'} name={'current_password'} />
          <br /><br />

          <button onClick={closeModalDeleteSubject} type={'button'} disabled={disabled} >Cancelar</button>&nbsp;
          <input type={'submit'} name='send' value={'Confirmar'} disabled={disabled} />
        </form>

        {
          errorFetch &&
          <>
            <h4 style={{color:'red'}}>No se pudo eliminar la carrera</h4>
            <p>  
              Es posible que la contraseña sea incorrecta.
            </p>
          </>
        }
      </Modal>

    </div>
  )
}
