import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { LS_USER } from '../../../helpers/storage';
import { ROUTE_USER_GET_ALL } from "../../../api-rest/back-cfp";
import { ROLE_STUDENT } from "../../../constants/roles";
import { simpleRunFetch } from "../../../helpers/fetch";
import { formatDateFull } from "../../../helpers/functions";
import { saveAnotherPersonUser } from "../../../features/anotherPersonUser/anotherPersonUserSlice";
import { updateSite } from "../../../helpers/functions";
import { SITE_ANOTHER_PERSON_PROFILE } from "../../../constants/sites";

export const Students = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  const [ students, setStudents ] = useState([]);

  useEffect( () => {
    getStudents();
  }, []);

  const getStudents = async () => {
    
    const route = [...ROUTE_USER_GET_ALL];
    route[0] += ROLE_STUDENT;
    
    try{
      const response = await simpleRunFetch( route, null, authorization );
      setStudents( response )
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const goProfile = ( student ) => {
    dispatch( saveAnotherPersonUser( student ) );
    updateSite( dispatch, site, SITE_ANOTHER_PERSON_PROFILE );
  }

  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  return (
    <div>
      <div className='title'>
        <h1>Alumnos</h1>
      </div>
      <table className='tableForList' >
        <tr className='oddRow'>
          <th className='column'>Apellidos</th>
          <th className='column'>Nombres</th>
          <th className='column'>DNI</th>
          <th className='column'>Email</th>
          <th className='column'>Celular</th>
          <th className='column'>Fecha de nacimiento</th>
          <th className='column'>Localidad actual</th>
          <th className='column'>Domicilio actual</th>
          <th className='column'>Perfil</th>
        </tr>
        {
          students &&
          students.map( (student, index) => {
            return (
              <tr className={getClassForRow(index)}>
                <td className='column'>{student.last_name}</td>
                <td className='column'>{student.name}</td>
                <td className='column'>{student.identification_number}</td>
                <td className='column'>{student.email}</td>
                <td className='column'>{student.cellphone_number}</td>
                <td className='column'>{ formatDateFull( student.date_of_birth ) }</td>
                <td className='column'>{student.location}</td>
                <td className='column'>{student.domicile}</td>
                <td className='column'><button onClick={ e => goProfile(student) } >Perfil</button></td>
              </tr>
            )
          })
        }
      </table>

    </div>
  )
}
