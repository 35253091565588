import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { LS_USER } from '../../../helpers/storage';
import { updateSite, formatDateFull } from '../../../helpers/functions';
import { useDispatch } from "react-redux";
import { saveLesson } from "../../../features/lesson/lessonSlice";
import { saveInscription } from '../../../features/inscription/inscriptionSlice';
import { simpleRunFetch } from '../../../helpers/fetch';
import { ROUTE_LESSON_SHOW, ROUTE_LESSON_UPDATE_STATE, ROUTE_INSCRIPTION_DELETE, ROUTE_USER_SEARCH_BY_IDENTIFICATION_NUMBER, ROUTE_INSCRIPTION_REGISTER, ROUTE_INSCRIPTION_STUDENTS_AND_CHECK_DEBT } from "../../../api-rest/back-cfp";
import { SITE_ATTENDANCE_AT_LESSON, SITE_MONTHLY_FEES } from '../../../constants/sites';
import { ROLE_ADMIN, ROLE_STUDENT } from "../../../constants/roles";
import { ComeBack } from '../ComeBack/ComeBack';
import { nf } from '../../../helpers/functions';
import Modal from 'react-modal';
import './Lessons.css';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const Lessons = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Obtenemos los datos que hay en Redux
  const subject = useSelector( state => state.subject );
  const schoolYear = useSelector( state => state.schoolYear );

  // Lista de lecciones e inscripciones que se muestra en la pantalla
  const [ lessons, setLessons ] = useState([]);
  const [ inscriptions, setInscriptions ] = useState([]);

  // Modal para eliminar una inscripcion
  const [modalDeleteInscription, setModalDeleteInscription] = useState(false);
  const [studentInscription, setStudentInscription] = useState(null);

  // Modal para agregar una nueva inscripcion
  const [modalNewInscription, setModalNewInscription] = useState(false);
  const [student, setStudent] = useState(null);

  // Estado de los buttons
  const [ disabled, setDisabled ] = useState( false );

  useEffect( () => {
    getLessons();
    getInscriptions();
  }, [] );

  const getLessons = async () => {
    let lessonsAux;
    try{
      let auxRoute = [...ROUTE_LESSON_SHOW];
      auxRoute[0] = auxRoute[0] + schoolYear.id;
      lessonsAux = await simpleRunFetch( auxRoute, null, authorization );
      // Agregamos la propiedad 'className'
      lessonsAux.lessons = lessonsAux.lessons.map( e => { return {...e, className: 'reasonForCancellationSaved' } } );
      console.log( 'lessonsAux', JSON.stringify( lessonsAux, null, 4 ) );
      setLessons( lessonsAux.lessons );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const getInscriptions = async () => {
    const date = new Date();
    const today_date = `${date.getFullYear()}-${nf(date.getMonth()+1,2)}-${nf(date.getDate(),2)}`;

    let route = [...ROUTE_INSCRIPTION_STUDENTS_AND_CHECK_DEBT];
    route[0] = route[0].replace( '{school_year_id}', schoolYear.id ).replace( '{today_date}', today_date );
    
    let inscriptionsAux;
    try{
      inscriptionsAux = await simpleRunFetch( route, null, authorization );
      console.log( 'inscriptionsAux', JSON.stringify( inscriptionsAux, null, 4 ) );
      setInscriptions( inscriptionsAux.inscriptions );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const clickCheckboxImparted = (e, lesson) => {
    lesson.imparted = e.target.checked;
    lesson.cancelled = false;
    lesson.reason_for_cancellation = '';
    updateLesson( lesson );
    fetchUpdateLessonState( lesson );
  }

  const clickCheckboxCancelled = (e, lesson) => {
    lesson.imparted = false;
    lesson.cancelled = e.target.checked;
    lesson.reason_for_cancellation = '';
    updateLesson( lesson );
    fetchUpdateLessonState( lesson );
  }

  const changeTextReasonForCancellation = (e, lesson) => {
    lesson.reason_for_cancellation = e.target.value;
    lesson.className = 'reasonForCancellationWithoutSaved';
    updateLesson( lesson );
  }

  const clickSaveReasonForCancellation = (e, lesson) => {
    lesson.className = 'reasonForCancellationSaved';
    updateLesson( lesson );
    fetchUpdateLessonState( lesson );
  }

  const updateLesson = (lesson) => {
    const auxLessons = lessons.map( (element) => {
      return element.id === lesson.id? 
        {
          ...element,
          imparted: lesson.imparted,
          cancelled: lesson.cancelled,
          reason_for_cancellation: lesson.reason_for_cancellation
        } : element;
    });
    setLessons(auxLessons);
  }

  const fetchUpdateLessonState = async (lesson) => {
    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_LESSON_UPDATE_STATE, lesson, authorization );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const clickAttendanceAtLessons = (e, lesson) => {
    dispatch( saveLesson( lesson ));
    updateSite( dispatch, site, SITE_ATTENDANCE_AT_LESSON );
  }

  const goMonthlyFees = (e, inscription_id) => {
    dispatch( saveInscription( { id: inscription_id } ));
    updateSite( dispatch, site, SITE_MONTHLY_FEES );
  }

  const clickDeleteInscription = (e, inscription) => {
    setStudentInscription( inscription );
    setModalDeleteInscription( true );
  }

  const closeModalDeleteInscription = () => {
    setModalDeleteInscription( false );
  }

  const deleteInscription = async ( inscription_id ) => {
    setDisabled( true );

    const route = [...ROUTE_INSCRIPTION_DELETE];
    route[0] += inscription_id;

    let response;
    try{ 
      response = await simpleRunFetch( route, null, authorization );
      await getInscriptions();
    }catch(e){
      console.log('-> ', e);
    }

    setDisabled( false );
    closeModalDeleteInscription();
  }

  const clickNewInscription = (e) => {
    setModalNewInscription( true );
  }

  const closeModalNewInscription = () => {
    setStudent( null );
    setModalNewInscription( false );
  }

  const searchByIdentificationNumber = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador
    setDisabled( true );

    const route = [...ROUTE_USER_SEARCH_BY_IDENTIFICATION_NUMBER];
    route[0] += e.target.identification_number.value + '?role_id=' + ROLE_STUDENT;

    let response;
    try{ 
      response = await simpleRunFetch( route, null, authorization );
      setStudent( response );
    }catch(e){
      console.log('-> ', e);
      setStudent( null );
    }

    setDisabled( false );
  }

  const inscriptionRegister = async ( student ) => {
    setDisabled( true );

    const route = [...ROUTE_INSCRIPTION_REGISTER];
    route[0] += '/' + student.id;

    const body = {
      school_year_id: schoolYear.id
    }

    let response;
    try{ 
      response = await simpleRunFetch( route, body, authorization );
      await getInscriptions();
    }catch(e){
      console.log('-> ', e);
    }

    setDisabled( false );
    closeModalNewInscription();
  }

  // Metodos del modal 'NewSubject'
  const getClassForRow = (index, invalid) => {
    if(invalid) return index % 2 === 0? 'invalidEvenRow' : 'invalidOddRow';
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  return (
    <div>
      <ComeBack />
      <div className='title'>
        <h1>{ subject.name }</h1>
        <h2>Desde { formatDateFull( schoolYear.start_date ) } hasta { formatDateFull( schoolYear.end_date ) }</h2>
      </div>

      <div className='layoutLessons'>

        <div className='col1'>

          <div>
              <h3>Planilla de clases:</h3>
          </div>

          <table className='tableForList' >
            <tr className='oddRow'>
              <th className='column'>Fecha</th>
              <th className='column'>Listas de asistencias</th>
              <th className='column'>Se dictó</th>
              <th className='column'>Se canceló</th>
              <th className='column'>Motivo de la cancelación</th>
            </tr>
            { 
              lessons.map( (lesson, index) => {

                return (
                  <tr className={getClassForRow(index)}>
                    <td className='column'>
                      { formatDateFull( lesson.date_dictated ) }
                    </td>
                    <td className='column'>
                      <button disabled={!lesson.imparted} onClick={ e => clickAttendanceAtLessons(e, lesson) }>
                        Tomar asistencia
                      </button>
                    </td>
                    <td className='column'>
                      <input type='checkbox' checked={lesson.imparted} onClick={ e => clickCheckboxImparted(e, lesson)}/>
                    </td>
                    <td className='column'>
                      <input type='checkbox' checked={lesson.cancelled} onClick={ e => clickCheckboxCancelled(e, lesson)}/>
                    </td>
                    <td className='column'>
                      <input type='text' className={lesson.className} disabled={!lesson.cancelled} value={lesson.reason_for_cancellation} onChange={ e => changeTextReasonForCancellation(e, lesson)} />
                      <button disabled={!lesson.cancelled} onClick={ e => clickSaveReasonForCancellation(e, lesson)} >
                        Guardar motivo
                      </button>
                    </td>
                  </tr>
                );
              }) 
            }
          </table>
        </div>

        <div className='col2'>
          <h3>Alumnos inscriptos:</h3>
          
          { 
            user.role_id === ROLE_ADMIN &&
            <button onClick={clickNewInscription}>Inscribir alumno</button>
          }

          <Modal
            isOpen={modalNewInscription}
            // onAfterOpen={}
            onRequestClose={closeModalNewInscription}
            style={modalStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            contentLabel="Modal para inscribir un estudiante a la carrera"
          >
            <div>
              <h2>Buscar alumno por DNI para inscribirlo</h2>
              <form onSubmit={searchByIdentificationNumber}>
                <label>Documento de identidad</label><br />
                <input type={'number'} name='identification_number' placeholder='Documento de identidad'></input>
                <input type={'submit'} name='send' value={'Buscar'} disabled={disabled} />
              </form>
              {
                student &&
                <table className='tableForList'>
                  <tr className='oddRow'>
                    <th>Nombre y apellido</th>
                    <th>DNI</th>
                    <th>email</th>
                    <th>Inscribir</th>
                  </tr>
                  <tr className='evenRow'>
                    <td>{student.name} {student.last_name}</td>
                    <td>{student.identification_number}</td>
                    <td>{student.email}</td>
                    <td><button onClick={e => inscriptionRegister(student)} disabled={disabled} >Inscribir</button></td>
                  </tr>
                </table>
              }
              <br/>
              <button onClick={closeModalNewInscription} disabled={disabled} >Cancelar</button>
            </div>
          </Modal>

          <Modal
            isOpen={modalDeleteInscription}
            // onAfterOpen={}
            onRequestClose={closeModalDeleteInscription}
            style={modalStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            contentLabel="Modal para dar de baja a un estudiante de una carrera"
          >
            <div>
              <h2>Dar de baja al alumno</h2>
              {
                studentInscription &&
                <p>Esta por eliminar la inscripcion del alumno <strong>{studentInscription.user.name} {studentInscription.user.last_name}</strong> a la carrera <strong>{subject.name}</strong>.</p>
              }
              <p>Una vez ejecutada esta accion, no se puede deshacer.</p>
              <button onClick={e => deleteInscription( studentInscription.id )} disabled={disabled} >Dar de baja</button>&nbsp;
              <button onClick={closeModalDeleteInscription} disabled={disabled}>Cancelar</button>
            </div>
          </Modal>

          <table className='tableForList' >
            <tr className='oddRow'>
              <th className='column'>Nombre</th>
              <th className='column'>Apellido</th>
              <th className='column'>DNI</th>
              {/* <th className='column'>Asistencia</th> */}
              <th className='column'>Cuotas</th>
              { 
                user.role_id === ROLE_ADMIN &&
                <th className='column'>Dar de baja</th>
              }
            </tr>
            
            {
              inscriptions.map( (inscription, index) => {
                return (
                  <tr className={getClassForRow(index, inscription.monthlyFees > 0)}>
                    <td className='column'>{inscription.user.name}</td>
                    <td className='column'>{inscription.user.last_name}</td>
                    <td className='column'>{inscription.user.identification_number}</td>
                    {/* <td className='column'>4/13 (31%)</td> */}
                    <td className='column'><button onClick={ e => goMonthlyFees(e, inscription.id) }>Cuotas</button></td>
                    { 
                      user.role_id === ROLE_ADMIN &&
                      <td className='column'><button onClick={e => clickDeleteInscription(e, inscription)}>Dar de baja</button></td>
                    }
                  </tr>
                );
              })
            }
          </table>
        </div>

      </div>
    </div>
  )
}
