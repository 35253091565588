import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  started_state: false,
  id: undefined,
}

export const inscriptionSlice = createSlice({
  name: 'inscription',
  initialState,
  reducers: {
    saveInscription: ( state, action ) => {
      state.started_state = true;
      state.id            = action.payload.id;
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveInscription } = inscriptionSlice.actions

export default inscriptionSlice.reducer