import React, { useEffect, useRef, useState } from 'react';
import { ROUTE_USER_CHANGE_PASSWORD_TO_ANOTHER_USER, ROUTE_USER_INFO, ROUTE_USER_UPDATE_ANOTHER_PERSON_PERSONAL_DATA } from '../../../api-rest/back-cfp';
import { simpleRunFetch } from '../../../helpers/fetch';
import { LS_USER } from '../../../helpers/storage';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DatePicker } from '@material-ui/pickers';
import { nf } from '../../../helpers/functions';
import { ComeBack } from '../ComeBack/ComeBack';
import Modal from 'react-modal';
import { PasswordInput } from '../../PasswordInput/PasswordInput';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const AnotherPersonProfile = () => {
  
  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Obtenemos los datos que hay en Redux
  const anotherPersonUser = useSelector( state => state.anotherPersonUser );

  const [ userInfo, setUserInfo ] = useState(null);
  const [ dateOfBirth, setDateOfBirth ] = useState( new Date() );
  const [ textMessage, setTextMessage ] = useState('');
  const [ colorTextMessage, setColorTextMessage ] = useState('');
  const refForm = useRef(null);

  const [ modalRestorePasswordIsOpen, setModalRestorePasswordIsOpen ] = useState(false);
  const [ errorRestorePassword, setErrorRestorePassword ] = useState( false );

  // Estado de los buttons
  const [ disabled, setDisabled ] = useState( false );

  useEffect( () => {
    getUserInfo();
  }, []);

  useEffect( () => {
    try{
      refForm.current.name.value                  = userInfo.name;
      refForm.current.last_name.value             = userInfo.last_name;
      refForm.current.identification_number.value = userInfo.identification_number;
      refForm.current.gender_id.value             = userInfo.gender_id;
      refForm.current.place_of_birth.value        = userInfo.place_of_birth;
      refForm.current.level_of_study_id.value     = userInfo.level_of_study_id;
      refForm.current.location.value              = userInfo.location;
      refForm.current.domicile.value              = userInfo.domicile;
      refForm.current.cellphone_number.value      = userInfo.cellphone_number;
      refForm.current.email.value                 = userInfo.email;
      setDateOfBirth( new Date( userInfo.date_of_birth + 'T00:00:00' ) );
    }catch(e){}
  }, [userInfo])

  // Metodo para pedir la info del usuario
  const getUserInfo = async () => {

    const route = [...ROUTE_USER_INFO];
    route[0] += '/' + anotherPersonUser.id;

    let response;
    try{ 
      response = await simpleRunFetch( route, null, authorization );
      console.log('response', response);
      setUserInfo( response );
    }catch(e){
      console.log('-> ', e);
      return;
    }

    console.log('queee hay', anotherPersonUser );
  }

  const updatePersonalData = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    const route = [...ROUTE_USER_UPDATE_ANOTHER_PERSON_PERSONAL_DATA];
    route[0] += anotherPersonUser.id;

    const body = {
      level_of_study_id     : e.target.level_of_study_id.value,
      gender_id             : e.target.gender_id.value,
      // email                 : e.target.email.value,
      name                  : e.target.name.value,
      last_name             : e.target.last_name.value,
      // identification_number : e.target.identification_number.value,
      date_of_birth         : `${dateOfBirth.getFullYear()}-${nf(dateOfBirth.getMonth()+1,2)}-${nf(dateOfBirth.getDate(),2)}`,
      place_of_birth        : e.target.place_of_birth.value,
      location              : e.target.location.value,
      domicile              : e.target.domicile.value,
      cellphone_number      : e.target.cellphone_number.value,
      // phone_number          : e.target.phone_number.value
    }

    if( e.target.email.value !== userInfo.email )
      body['email'] = e.target.email.value;
    
    if( e.target.identification_number.value !== userInfo.identification_number.toString() )
      body['identification_number'] = e.target.identification_number.value;

    console.log( 'body', body );

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( route, body, authorization );
    }catch(e){
      console.log('-> ', e);
      setTextMessage( 'Error, intente nuevamente' );
      setColorTextMessage( 'red' );
      return;
    }

    // setUserInfo( e.target );
    setUserInfo( {...userInfo, ...body} );
  }

  const openModalRestorePassword = () => {
    setModalRestorePasswordIsOpen( true );
    setErrorRestorePassword(false);
  }

  const closeModalRestorePassword = () => {
    setModalRestorePasswordIsOpen( false );
  }

  const userRestorePassword = async e => {
    e.preventDefault(); // Evitamos que se recargue la pantalla con el onSubmit del navegador

    setDisabled( true );

    const body = {
      user_id: anotherPersonUser.id,
      new_password: e.target.new_password.value,
      repet_new_password: e.target.repet_new_password.value
    };

    // Enviamos la peticion y esperamos la respuesta
    let response = undefined;
    try{
      response = await simpleRunFetch( ROUTE_USER_CHANGE_PASSWORD_TO_ANOTHER_USER, body, authorization );
      closeModalRestorePassword();
    }catch(e){
      console.log('-> ', e);
      setErrorRestorePassword( true );
    }

    setDisabled( false );
  }
  
  return (
    <>
    <ComeBack />
    <form ref={refForm} onSubmit={updatePersonalData}>
      <div className='layoutRegister'>
        <div className='title'>
          {
            userInfo &&
            <h1>Perfil de {userInfo.name} {userInfo.last_name}</h1>
          }
          <button onClick={openModalRestorePassword}>Reestablecer contraseña</button>

          <Modal
            isOpen={modalRestorePasswordIsOpen}
            // onAfterOpen={afterOpenModalInscriptionIsOpen}
            onRequestClose={closeModalRestorePassword}
            style={modalStyles}
            ariaHideApp={false}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            contentLabel="Modal para restaurar la contraseña"
          >
            <h2>Restaurar contraseña</h2>
            <form onSubmit={userRestorePassword} >

              <PasswordInput label={'Nueva contraseña'} name={'new_password'} />
              <br /><br />

              <PasswordInput label={'Repita la nueva contraseña'} name={'repet_new_password'} />
              <br /><br />

              <button onClick={closeModalRestorePassword} disabled={disabled} type='button' >Cancelar</button>&nbsp;
              <input type={'submit'} name='send' value={'Confirmar'} disabled={disabled} />

            </form>
            {
              errorRestorePassword &&
              <>
                <h4 style={{color:'red'}}>No se pudo restaurar la contraseña</h4>
                <p>  
                  Revisa que la nueva contraseña tenga al menos 8 carácteres;<br />
                  y que el campo para repetir la contraseña este correcto.
                </p>
              </>
            }
          </Modal>

        </div>
        <div className='col1' >

          <h3>Datos personales</h3>
          
          <label>Nombres</label><br />
          <input type={'text'} name='name' placeholder='Nombres'  ></input><br/><br />

          <label>Apellidos</label><br />
          <input type={'text'} name='last_name' placeholder='Apellidos'  ></input><br/><br />
          
          <label>Documento de identidad</label><br />
          <input type={'number'} name='identification_number' placeholder='Documento de identidad'  ></input><br/><br />
          
          <label>Sexo</label><br />
          <select name="gender_id" id="gender_id">
            <option value="1">Masculino</option>
            <option value="2">Femenino</option>
          </select>
          <br /><br />

          <label>Fecha de nacimiento</label><br />
          <DatePicker value={dateOfBirth} onChange={setDateOfBirth} /><br /><br />

          <label>Lugar de nacimiento</label><br />
          <input type={'text'} name='place_of_birth' placeholder='Lugar de nacimiento'  ></input><br/><br />
          
          <label>Nivel completo de estudios</label><br />
          <select name="level_of_study_id" id="level_of_study_id">
            <option value="1">Primario</option>
            <option value="2">Secundario</option>
            <option value="3">Terciario</option>
            <option value="4">Universitario</option>
          </select>
          <br />

        </div>
        <div className='col2' >

          <h3>Datos de contacto</h3>
          
          <label>Localidad</label><br />
          <input type={'text'} name='location' placeholder='Localidad' ></input><br/><br />

          <label>Domicilio</label><br />
          <input type={'text'} name='domicile' placeholder='Domicilio' ></input><br/><br />
          
          <label>Celular</label><br />
          <input type={'text'} name='cellphone_number' placeholder='Celular' ></input><br/><br />
          
          <h3>Datos de la cuenta</h3>

          <label>Correo electrónico</label><br />
          <input type={'text'} name='email' placeholder='Correo electrónico' ></input><br/><br />

          <input type={'submit'} name='send' value={'Actualizar'} />

        </div>

        <div className='message'>
          <label style={{ color: colorTextMessage }}>{textMessage}</label>
        </div>
      </div>
    </form>
    </>
  )
}
