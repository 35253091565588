import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  started_state: false,
  id: undefined,
}

export const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    saveLesson: ( state, action ) => {
      state.started_state = true;
      state.id            = action.payload.id;
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveLesson } = lessonSlice.actions

export default lessonSlice.reducer