import React, { useRef, useState } from 'react';

const InputType = {
  PASSWORD: 'password',
  TEXT: 'text'
}

const ButtonInputType = {
  SHOW: 'Ver',
  HIDE: 'Ocultar'
}

export const PasswordInput = ( {label, name, placeholder} ) => {

  const refInput = useRef(null);
  const [ inputType, setInputType ] = useState( InputType.PASSWORD );
  const [ buttonInputType, setButtonInputType ] = useState( ButtonInputType.SHOW );

  const clickShowOrHide = () => {
    setInputType( inputType === InputType.PASSWORD? InputType.TEXT : InputType.PASSWORD );
    setButtonInputType( buttonInputType === ButtonInputType.SHOW? ButtonInputType.HIDE : ButtonInputType.SHOW );
  }

  return (
    <>
      <label>{label}</label><br />
      <input
        ref={refInput}
        type={inputType}
        name={name}
        placeholder={placeholder}
      ></input>
      &nbsp;
      <button onClick={clickShowOrHide} type='button' >{buttonInputType}</button>
    </>
  )
}
