export const runFetch = async ( route, method = 'GET', body = null, authorization = null ) => {
  // Construimos la metadata y la request
  const metadata = {
    method,
    headers:{
      'Content-Type': 'application/json'
    }
  };

  // Adjuntamos el body
  if( body ) metadata['body'] = JSON.stringify( body );

  // Adjuntamos la authorization
  if( authorization ) metadata.headers['Authorization'] = authorization;

  console.log( metadata, JSON.stringify( metadata, null, 4 ) );

  // Enviamos la consulta
  let response = await fetch(
    route,
    metadata
  );

  if (!response.ok) 
		throw new Error(`HTTP error(runFetch)! status: ${response.status}`);

  // Convertimos la respuesta a json
  const responseJson = await response.json();
  return responseJson;
};

export const simpleRunFetch = async ( route, body = null, authorization = null ) => {
  if( Array.isArray( route ) && route.length >= 2 )
    return await runFetch( route[0], route[1], body, authorization );
  throw new Error(`Bad route: ${route}`);
};