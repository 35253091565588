export const months = [
  {
    id: 'january',
    name: 'Enero',
    numberId: 1
  },
  {
    id: 'february',
    name: 'Febrero',
    numberId: 2
  },
  {
    id: 'march',
    name: 'Marzo',
    numberId: 3
  },
  {
    id: 'april',
    name: 'Abril',
    numberId: 4
  },
  {
    id: 'may',
    name: 'Mayo',
    numberId: 5
  },
  {
    id: 'june',
    name: 'Junio',
    numberId: 6
  },
  {
    id: 'july',
    name: 'Julio',
    numberId: 7
  },
  {
    id: 'august',
    name: 'Agosto',
    numberId: 8
  },
  {
    id: 'september',
    name: 'Septiembre',
    numberId: 9
  },
  {
    id: 'october',
    name: 'Octubre',
    numberId: 10
  },
  {
    id: 'november',
    name: 'Noviembre',
    numberId: 11
  },
  {
    id: 'december',
    name: 'Diciembre',
    numberId: 12
  }
];