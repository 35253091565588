import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { LS_USER } from '../../../helpers/storage';
import { ROUTE_INSCRIPTION_SHOW } from '../../../api-rest/back-cfp';
import { simpleRunFetch } from '../../../helpers/fetch';
import { nf, dayNumberToString, formatDateFull, updateSite } from '../../../helpers/functions';
import { saveInscription } from '../../../features/inscription/inscriptionSlice';
import { SITE_MONTHLY_FEES } from '../../../constants/sites';

export const SchoolYearsStudying = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  const [ inscriptions, setInscriptions ] = useState([]);

  useEffect( () => {
    getInscriptions();
  }, [] );

  const getInscriptions = async () => {
    let inscriptionsAux;
    try{ 
      inscriptionsAux = await simpleRunFetch( ROUTE_INSCRIPTION_SHOW, null, authorization );
      console.log( 'inscriptionsAux', JSON.stringify( inscriptionsAux, null, 4 ) );
      setInscriptions( inscriptionsAux );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const goMonthlyFees = (e, inscription_id) => {
    dispatch( saveInscription( { id: inscription_id } ));
    updateSite( dispatch, site, SITE_MONTHLY_FEES );
  }

  // Metodos del modal 'NewSubject'
  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  return (
    <>
      <div className='title'>
        <h1>Mis carreras</h1>
      </div>
      <div>
        <table className='tableForList' >
          <tr className='oddRow'>
            <th className='column'>Carrera</th>
            <th className='column'>Inicio del ciclo</th>
            <th className='column'>Final del ciclo</th>
            <th className='column'>Aula</th>
            <th className='column'>Horario</th>
            <th className='column'>Cuotas</th>
          </tr>
          {
            inscriptions.map( (inscription, index) => {

              let lessonSchedules = inscription.school_year?.lesson_schedule?.map( e => {
                return `${ dayNumberToString( e.day_number ) } de ${nf(e.start_time,2)}:${nf(e.start_minute,2)} a ${nf(e.end_time,2)}:${nf(e.end_minute,2)} hs.`;
              });
              lessonSchedules = lessonSchedules.join('<br/>');

              return (
                <tr className={getClassForRow(index)}>
                  <td className='column'>{inscription.school_year.subject.name}</td>
                  <td className='column'>{ formatDateFull( inscription.school_year.start_date ) }</td>
                  <td className='column'>{ formatDateFull( inscription.school_year.end_date ) }</td>
                  <td className='column'>{inscription.school_year.classroom}</td>
                  <td className='column' dangerouslySetInnerHTML={{__html: lessonSchedules}} ></td>
                  <td className='column'><button onClick={ e => goMonthlyFees( e, inscription.id ) }>Cuotas</button></td>
                </tr>
              );

            })
          }
        </table>
      </div>
    </>
  )
}
