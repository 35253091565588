import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { SITE_CHARGE_FEES, SITE_RECEPTIONISTS, SITE_GET_QR, SITE_INSCRIPTIONS, SITE_LOGIN, SITE_MY_PROFILE, SITE_REGISTER_STUDENT, SITE_SCHOOL_YEARS_STUDYING, SITE_STUDENTS, SITE_STUDENT_SEE_FEES, SITE_SUBJECTS, SITE_TAKE_ATTENDANCE_AUTOMATICALLY, SITE_TUITIONS } from '../../constants/sites';
import { saveSite } from '../../features/site/siteSlice';
import { saveUser, USER_INITIAL_STATE } from '../../features/user/userSlice';
import { updateSite } from '../../helpers/functions';
import { LS_USER } from '../../helpers/storage';

export const NAVIGATIONS = [
  // ROLE_UNDEFINED
  [
    { title: 'Iniciar sesion', site: SITE_LOGIN, primary: true },
    { title: 'Registrarse', site: SITE_REGISTER_STUDENT },
  ],
  // ROLE_ROOT
  [
    { title: 'Carreras', site: SITE_SUBJECTS, primary: true },
    { title: 'Recepcionistas', site: SITE_RECEPTIONISTS },
    { title: 'Alumnos', site: SITE_STUDENTS },
    { title: 'Matrículas', site: SITE_TUITIONS },
    { title: 'Cobrar cuotas', site: SITE_CHARGE_FEES },
    { title: 'Mi perfil', site: SITE_MY_PROFILE },
    { title: 'Cerrar sesión', site: SITE_LOGIN },
  ],
  // ROLE_ADMIN
  [
    { title: 'Carreras', site: SITE_SUBJECTS, primary: true },
    { title: 'Recepcionistas', site: SITE_RECEPTIONISTS },
    { title: 'Alumnos', site: SITE_STUDENTS },
    { title: 'Matrículas', site: SITE_TUITIONS },
    { title: 'Cobrar cuotas', site: SITE_CHARGE_FEES },
    { title: 'Mi perfil', site: SITE_MY_PROFILE },
    { title: 'Cerrar sesión', site: SITE_LOGIN },
  ],
  // ROLE_RECEPTIONIST
  [
    // { title: 'Ciclos de carreras', site: SITE_SCHOOL_YEARS },
    { title: 'Carreras', site: SITE_SUBJECTS, primary: true },
    { title: 'Matrículas', site: SITE_TUITIONS },
    { title: 'Cobrar cuotas', site: SITE_CHARGE_FEES },
    { title: 'Mi perfil', site: SITE_MY_PROFILE },
    { title: 'Cerrar sesión', site: SITE_LOGIN },
  ],
  // ROLE_STUDENT
  [
    { title: 'Mis carreras', site: SITE_SCHOOL_YEARS_STUDYING, primary: true },
    { title: 'Inscripción', site: SITE_INSCRIPTIONS },
    { title: 'Obtener QR', site: SITE_GET_QR },
    { title: 'Mi perfil', site: SITE_MY_PROFILE },
    { title: 'Cerrar sesión', site: SITE_LOGIN },
  ],
  // ROLE_TOTEM
  [
    { title: 'Totem', site: SITE_TAKE_ATTENDANCE_AUTOMATICALLY, primary: true },
    { title: 'Cerrar sesión', site: SITE_LOGIN },
  ],
];

export const Nav = () => {
  // Obtenemos dispatch para las actualizaciones de Redux
  const dispatch = useDispatch();

  // Obtenemos el usuario desde Redux
  const user = useSelector( state => state.user );
  const site = useSelector( state => state.site );

  // Obtenemos las opciones de navegacion
  const navigationOptions = NAVIGATIONS[ user.role_id ];

  const clickNavigation = ( event, navigationOption ) => {
    // Si es igual a ir a login, significa que esta cerrando su sesion
    if( navigationOption.site === SITE_LOGIN ){
      localStorage.removeItem( LS_USER );
      dispatch( saveUser( USER_INITIAL_STATE ) );
    }
    updateSite( dispatch, site, navigationOption.site );
  }

  return (
    <nav className="nav">
      <ul>
        {
          navigationOptions.map( n => {
            return <li key={n.title}><a onClick={ e => clickNavigation( e, n ) }>{n.title}</a></li>
          })
        }
      </ul>
    </nav>
  )
}
