import { createSlice } from '@reduxjs/toolkit'

export const USER_INITIAL_STATE = {
  started_state: false,
  user_id: 0,
  role_id: 0,
  name: undefined,
  last_name: undefined,
  access_token: undefined,
  token_type: undefined
}

export const userSlice = createSlice({
  name: 'user',
  initialState: USER_INITIAL_STATE,
  reducers: {
    saveUser: ( state, action ) => {
      state.started_state = true;
      state.user_id       = action.payload.user_id;
      state.role_id       = action.payload.role_id;
      state.name          = action.payload.name;
      state.last_name     = action.payload.last_name;
      state.access_token  = action.payload.access_token;
      state.token_type    = action.payload.token_type;
    }
  },
})

// Action creators are generated for each case reducer function
export const { saveUser } = userSlice.actions

export default userSlice.reducer