import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { ComeBack } from '../ComeBack/ComeBack';
import { LS_USER } from '../../../helpers/storage';
import { ROUTE_MONTHLY_FEES_SHOW, ROUTE_MONTHLY_FEES_SHOW_MY, ROUTE_MONTHLY_FEES_TAKE_FEE } from '../../../api-rest/back-cfp';
import { simpleRunFetch } from '../../../helpers/fetch';
import { nf, formatDateFull, formatDateMonthAndYear } from '../../../helpers/functions';
import { ROLE_ADMIN, ROLE_RECEPTIONIST, ROLE_ROOT, ROLE_STUDENT } from "../../../constants/roles";

export const MonthlyFees = () => {

  const user = JSON.parse( localStorage.getItem( LS_USER ) );
  const authorization = `${user.token_type} ${user.access_token}`;
  const site = useSelector( state => state.site );

  // Obtenemos los datos que hay en Redux
  const inscriptionRedux = useSelector( state => state.inscription );

  const [ monthlyFeesDetail, setMonthlyFeesDetail ] = useState(null);

  useEffect( () => {
    getMonthlyFeesDetail();
  }, []);

  const getMonthlyFeesDetail = async () => {
    let auxMonthlyFeesDetail;
    try{
      let auxRoute = user.role_id === ROLE_STUDENT? [...ROUTE_MONTHLY_FEES_SHOW_MY] : [...ROUTE_MONTHLY_FEES_SHOW];
      auxRoute[0] = auxRoute[0] + inscriptionRedux.id;
      auxMonthlyFeesDetail = await simpleRunFetch( auxRoute, null, authorization );
      console.log( 'auxMonthlyFeesDetail', JSON.stringify( auxMonthlyFeesDetail, null, 4 ) );
      setMonthlyFeesDetail( auxMonthlyFeesDetail.inscription );
    }catch(e){
      console.log('-> ', e);
      return;
    }
  }

  const checkboxTakeFee = async (e, monthlyFee) => {

    if( e.target.checked ){
      const date = new Date();
      monthlyFee.payment_date = `${date.getFullYear()}-${nf(date.getMonth()+1,2)}-${nf(date.getDate(),2)}`
    }else monthlyFee.payment_date = null;

    if( await takeFee( monthlyFee ) ){
      monthlyFee.payment_date = monthlyFee.payment_date === null? false : monthlyFee.payment_date;
      setMonthlyFeesDetail( {...monthlyFeesDetail} );
    }
    
  }

  const takeFee = async ( monthlyFee ) => {
    try{
      const takeFee = await simpleRunFetch( ROUTE_MONTHLY_FEES_TAKE_FEE, monthlyFee, authorization );
      console.log( 'takeFee', JSON.stringify( takeFee, null, 4 ) );
      return true;
    }catch(e){
      console.log('-> ', e);
      return false;
    }
  }

  const getClassForRow = index => {
    return index % 2 === 0? 'evenRow' : 'oddRow';
  }

  return (
    <div>
      <ComeBack />
      <div className='title'>
        {
          monthlyFeesDetail &&          
          <>
            { user.role_id === ROLE_ADMIN && <h1>Cuotas de {monthlyFeesDetail.user.name} {monthlyFeesDetail.user.last_name}</h1> }
            <h2>{monthlyFeesDetail.school_year.subject.name}</h2>
            <h3>Ciclo del { formatDateFull( monthlyFeesDetail.school_year.start_date )} al { formatDateFull( monthlyFeesDetail.school_year.end_date ) }</h3>
          </>
        }
      </div>
      <div>
        <table className='tableForList' >
          <tr className='oddRow'>
            <th className='column'>Cuota</th>
            <th className='column'>Abonado</th>
            <th className='column'>Fecha</th>
          </tr>

          {
            monthlyFeesDetail &&
            monthlyFeesDetail.monthly_fees.map( (monthlyFee, index) => {
              return (
                <tr className={getClassForRow(index)}>
                  <td className='column'>
                    {formatDateMonthAndYear( monthlyFee.monthly_fee_date )}
                  </td>
                  <td className='column'>
                    <input type="checkbox" disabled={ user.role_id === ROLE_STUDENT } checked={ monthlyFee.payment_date } onChange={ e => checkboxTakeFee(e, monthlyFee) } />
                  </td>
                  <td className='column'>
                    { formatDateFull( monthlyFee.payment_date ) }
                  </td>
                </tr>
              );
            })
          }
        </table>
      </div>
    </div>
  )
}
